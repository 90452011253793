import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import router, {
        DOCUMENT_TYPE_MAPPING_ROUTE_NAME,
        HOME_ROUTE_NAME,
        MATCHING_LIST_ROUTE_NAME,
        PARTNER_LIST_ROUTE_NAME,
        TRANSACTION_LIST_ROUTE_NAME
} from "@/router";
import {navigationErrorHandler} from "@/store/mutations";
import {
        FLUENT_ICON_DOCUMENT_TYPE_MAPPINGS,
        FLUENT_ICON_HOME,
        FLUENT_ICON_MATCHINGS, FLUENT_ICON_PARTNERS,
        FLUENT_ICON_TRANSACTIONS, STATE_DOCUMENT_TYPE_MAPPINGS,
        STATE_HOME,
        STATE_MATCHING, STATE_PARTNER, STATE_TRANSACTION
} from "@/types/StateConst";

export const HOME_BUTTON = new UFButtonOptions(
    () => {router.push({name: HOME_ROUTE_NAME}).catch(err => {navigationErrorHandler(err)})},
    "",
    FLUENT_ICON_HOME,
    STATE_HOME
);

export const MATCHING_LIST_BUTTON = new UFButtonOptions(
    () => {router.push({name: MATCHING_LIST_ROUTE_NAME}).catch(err => {navigationErrorHandler(err)})},
    "",
    FLUENT_ICON_MATCHINGS,
    STATE_MATCHING
)

export const TRANSACTION_LIST_BUTTON =  new UFButtonOptions(
    () => {router.push({name: TRANSACTION_LIST_ROUTE_NAME}).catch(err => {navigationErrorHandler(err)})},
    "",
    FLUENT_ICON_TRANSACTIONS,
    STATE_TRANSACTION
)

export const PARTNER_LIST_BUTTON = new UFButtonOptions(
        () => {router.push({name: PARTNER_LIST_ROUTE_NAME}).catch(err => {navigationErrorHandler(err)})},
        "",
        FLUENT_ICON_PARTNERS,
        STATE_PARTNER
)

export const DOCUMENT_TYPE_MAPPING_BUTTON = new UFButtonOptions(
        () => {router.push({name: DOCUMENT_TYPE_MAPPING_ROUTE_NAME}).catch(err => {navigationErrorHandler(err)})},
        "",
        FLUENT_ICON_DOCUMENT_TYPE_MAPPINGS,
        STATE_DOCUMENT_TYPE_MAPPINGS,
)
