var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tool-content"},[(_vm.error)?_c('div',{staticClass:"p-3"},[_vm._v(_vm._s(_vm.error))]):_c('DxDataGrid',{key:_vm.renderToggle,staticClass:"content-block-spaced fill-space",attrs:{"data-source":_vm.matchings,"columns":_vm.dxColumns,"remote-operations":_vm.remoteMatchingOperations,"no-data-text":_vm.notFoundText,"master-detail":{template: 'matchingListMasterDetail'},"on-row-click":_vm._expandRow},on:{"cell-prepared":_vm.onCellPrepared},scopedSlots:_vm._u([{key:"matchingListMasterDetail",fn:function(ref){
var selectedMatching = ref.data;
return [_c('div',[_c('div',{staticClass:"matching-list-detail-headline"},[_c('i',{class:_vm.iconDetail}),_c('div',{staticClass:"light"},[_vm._v("Matching Details")])]),_c('div',{staticClass:"matching-list-detail"},[_c('div',[_c('div',{staticClass:"field-with-label"},[_c('div',[_vm._v("Material Einheit")]),_c('div',[_vm._v(_vm._s(selectedMatching.data.client.materialUnit))])]),_c('div',{staticClass:"field-with-label"},[_c('div',[_vm._v("Service Einheit")]),_c('div',[_vm._v(_vm._s(selectedMatching.data.client.serviceUnit))])])]),_c('div',[_c('div',{staticClass:"field-with-label"},[_c('div',[_vm._v("Turnus")]),_c('div',[_vm._v(_vm._s(selectedMatching.data.client.cycleRhythm))])]),_c('div',{staticClass:"field-with-label"},[_c('div',[_vm._v("Turnus Information")]),_c('div',[_vm._v(_vm._s(selectedMatching.data.client.cycleInformation))])])])]),(selectedMatching.data.extendedInformation.size!==0)?[_c('div',{staticClass:"matching-list-detail-sub-headline light"},[_vm._v("Weitere Information(en)")]),_c('div',{staticClass:"matching-list-detail"},[_vm._l((selectedMatching.data.extendedInformation),function(ref){
var key = ref[0];
var value = ref[1];
return [_c('div',{key:key,staticClass:"field-with-label"},[_c('div',{key:key},[_vm._v(_vm._s(key))]),_c('div',{key:value.type + value.content},[_vm._v(_vm._s(value.type)+": "+_vm._s(value.content))])])]})],2)]:_vm._e(),_c('div',{staticClass:"matching-list-detail-command-button-wrapper"},[_c('DxButton',{staticClass:"aval-button",attrs:{"visible":selectedMatching.data.isAbortable,"icon":_vm.iconReject,"text":selectedMatching.data.isMatchable? 'Matching ablehnen' : 'Matching abbrechen'},on:{"click":function($event){return _vm.abortButtonClick(selectedMatching.data)}}})],1)],2)]}},{key:"commandTemplate",fn:function(ref){
var row = ref.data;
return [_c('DxButton',{staticClass:"iconButtonWithoutText",attrs:{"icon":_vm.iconProceed,"hint":"Details"},on:{"click":function($event){return _vm.proceedButtonClick(row.data)}}})]}},{key:"statusIconTemplate",fn:function(ref){
var row = ref.data;
return [_c('StatusIcon',{attrs:{"initials":_vm.getInitials(row.data.client.contractPartner),"css-class":row.data.getBackgroundStatusClass(),"synced":row.data.lastSync !== null}})]}},{key:"customerTemplate",fn:function(ref){
var row = ref.data;
return [_c('PrimarySecondaryElement',{attrs:{"primary":row.data.client.contractPartner,"secondary":row.data.client.performancePlace,"wrap-content":true}})]}},{key:"requestMetadataTemplate",fn:function(ref){
var row = ref.data;
return [_c('PrimarySecondaryElement',{attrs:{"primary":_vm.getDateTimeString(row.data.requestDate),"secondary":row.data.requestedBy}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }