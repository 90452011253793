import DataSource from 'devextreme/data/data_source';
import {RemoteOperations} from "@/types/RemoteOperations";
import {ResultPage} from "@gipa/devextreme-to-spring";
import {Partner} from "@/types/Partner";
import PartnerConnector from "@/components/partner/PartnerConnector";

export const remoteOperations: RemoteOperations = {
    paging: true,
    sorting: true,
    filtering: true,
};

const partnerSource: DataSource = new DataSource({
    load: function (loadOptions): Promise<ResultPage<Partner>> {
        return PartnerConnector.getInstance().filter(loadOptions);
    },
    insert: function (values): Promise<Partner> {
        return PartnerConnector.getInstance().create(values)
    }
});

export default partnerSource;
