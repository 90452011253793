
































































import {Component, Vue} from "vue-property-decorator";
import {DxLoadPanel} from "devextreme-vue";
import DxDataGrid from 'devextreme-vue/data-grid';
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getInitials} from '@/util/string.module';
import DxButton from "devextreme-vue/button";
import agreementDataSource, {remoteOperations} from "@/components/matching/AgreementDatasource";
import {FLUENT_ICON_MATCHING_CREATION, FLUENT_ICON_PROCEED, FLUENT_ICON_REJECT, STATE_MATCHING_CREATION} from "@/types/StateConst";
import {RemoteOperations} from "@/types/RemoteOperations";
import {ViewLabel} from "@/store/types";
import {Agreement} from "@/types/Agreement";
import MatchingCreationStatusBar from "@/components/matchingCreation/MatchingCreationStatusBar.vue";
import DataSource = DevExpress.data.DataSource;
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;
import {CREATE_MATCHING_AGREEMENT_SELECTION_ROUTE_NAME, MATCHING_LIST_ROUTE_NAME} from "@/router";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {EventBus} from "@/main";

@Component({
    components: {
        MatchingCreationStatusBar,
        DxDataGrid,
        DxLoadPanel,
        StatusIcon,
        DxButton,
    },
    methods: {
        getInitials: getInitials,
    },
})
export default class MatchableAgreementSelection extends Vue {

    private readonly FLUENT_ICON_PROCEED = FLUENT_ICON_PROCEED;

    public static readonly BASIC_VIEW_LABEL: ViewLabel = {
        primary: "Anfrage erstellen",
        secondary: false,
        icon: FLUENT_ICON_MATCHING_CREATION,
        cssClass: "matchingCreation",
    };

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_MATCHING_CREATION,
        false,
        { name: CREATE_MATCHING_AGREEMENT_SELECTION_ROUTE_NAME }
    );

    renderToggle: boolean = false;
    readonly loadingText: string = "Lade ...";
    readonly notFoundText: string = "Es liegen keine Vereinbarungen vor.";

    error: any = null;

    processing: boolean = false;
    readonly processingPosition = {of: '#agreementGrid'};
    readonly processingMessage: string = "Verarbeite Vereinbarung ...";
    processingButtonClick: boolean = false;

    remoteAgreementOperations: RemoteOperations = remoteOperations;
    agreements: DataSource = agreementDataSource;

    dxAgreementColumns: dxDataGridColumn[] = [
        {
            caption: '',
            allowSorting: false,
            allowFiltering: false,
            cssClass: "align-middle",
            cellTemplate: "statusIconTemplate",
            dataType: "object",
            width: "52px",
        },
        {
            caption: 'Kunde',
            dataField: 'customer',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
        },
        {
            caption: 'Anfallstelle',
            dataField: 'location',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
        },
        {
            caption: 'Bezeichnung',
            dataField: 'title',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            filterOperations: ['contains'],
        },
        {
            caption: 'Beschreibung',
            dataField: 'description',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
        },
        {
            cellTemplate: "commandTemplate",
            allowSorting: false,
            allowFiltering: false,
            dataType: "object",
            alignment: "center",
            width: "49px",
        },
    ];

    private readonly cancelCommandButton: UFButtonOptions = new UFButtonOptions(
        () => { this.$router.push({name: MATCHING_LIST_ROUTE_NAME}); },
        "Erstellung abbrechen",
        FLUENT_ICON_REJECT,
        "Den Prozess zum Erstellen eines Matchings abbrechen"
    )

    mounted(): void {
        this.$store.commit("clearAgreement");
        this.$store.commit("clearPartner");
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
        this.$store.commit("updateViewLabel", MatchableAgreementSelection.BASIC_VIEW_LABEL);

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.cancelCommandButton);
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.cancelCommandButton);
    }

    handleRefresh(): void {
        this.agreements.reload();
        this.refreshView();
    }

    _expandRow(e: any): void {
        e.component.collapseAll(-1);
        if (!e.isExpanded) {
            e.component.expandRow(e.key);
        }
    }

    proceedToPartnerSelection(agreement: Agreement) {
        if (!this.processingButtonClick) {
            this.processingButtonClick = true;

            this.processing = true;
            if (agreement) {
                this.$store.commit("selectAgreement", agreement);
                this.$router.push({
                    name: 'partnerSelection',
                    params: {href: agreement.self, toolName: STATE_MATCHING_CREATION,},
                });
            }
        }
    }

    refreshView(): void {
        this.renderToggle = !this.renderToggle;
    }

}

