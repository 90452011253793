import dxDataGrid from "devextreme/ui/data_grid";

export function iniDefaultOptions(): void {
    iniDataGridOptions();
}

function iniDataGridOptions(): void {
    dxDataGrid.defaultOptions({
        options: {
            showBorders: false,
            showColumnLines: false,
            showRowLines: true,
            hoverStateEnabled: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            allowColumnResizing: true,
            columnResizingMode: "widget",
            loadPanel: {
                enabled: true,
                shading: true,
                shadingColor: "rgba(0,0,0,0.4)",
            },
            columnChooser: {
                enabled: true,
            },
            filterRow: {
                visible: "true",
                applyFilter: "auto",
            },
            selection: {
                mode: "single",
            },
            masterDetail: {
                enabled: false,
            }
        },
    });
}
