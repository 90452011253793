



















import DxButton from 'devextreme-vue/button';
import {Component, Vue} from "vue-property-decorator";
import {ViewLabel} from "@/store/types";
import {State} from "vuex-class";
import MatchingRequestContext from "@/components/frame/MatchingRequestContext.vue";
import AgreementContext from "@/components/frame/AgreementContext.vue";

@Component({
    components: {
        AgreementContext,
        MatchingRequestContext,
        DxButton
    },
})

export default class ToolHeaderBar extends Vue {
    @State(state => state.viewLabel) viewLabel: ViewLabel | undefined;
    @State(state => state.matchingContextVisible) matchingContextVisible: boolean | undefined;
    @State(state => state.agreementContextVisible) agreementContextVisible: boolean | undefined;
}
