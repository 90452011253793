





























import {Component, Vue} from "vue-property-decorator";
import {State} from "vuex-class";
import {Matching} from "@/types/Matching";
import {getDateString} from "@/util/string.module";

@Component({
    components: {
    },
    methods: {
        getDateString: getDateString,
    },
})
export default class MatchingRequestContext extends Vue {
    @State(state => state.matching) matching: Matching | null | undefined;

}

