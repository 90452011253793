import {Matching, StatusEnum} from "@/types/Matching";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {GenericEntityConnector, PropertyMapping} from "@gipa/devextreme-to-spring";

type patchMatchAgreementBody = {
    avalState: StatusEnum;
    matchedAgreement: string;
}

export default class MatchingConnector {
    private static readonly propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>(
        [
            ["id", "avalId"],
            ["status", "avalState"],
            ["requestDate", "requestedOn"],
        ]
    ));

    static connector: GenericEntityConnector<Matching> = new GenericEntityConnector<Matching>(
        MatchingConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "matchings",
        Matching,
        "requestDate",
        true,
    );

    public static getInstance(): GenericEntityConnector<Matching> {
        return MatchingConnector.connector;
    }

    public static cancelMatching(matching: Matching): Promise<Matching> {
        const body: object = {
            avalState: 3,
        };
        return MatchingConnector.connector.update(body, matching.self);
    }

    public static matchAgreement(matching: Matching, agreementLink: string): Promise<Matching> {
        const body: patchMatchAgreementBody = {
            avalState: StatusEnum.matched,
            matchedAgreement: agreementLink,
        };
        return MatchingConnector.connector.update(body, matching.self);
    }

    public static updateRequiredDocuments(matching: Matching, requiredDocuments: string[]): Promise<Matching> {
        const body = {
            requiredDocuments: requiredDocuments
        }
        return MatchingConnector.connector.update(body, matching.self);
    }

}
