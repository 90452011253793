import DataSource from "devextreme/data/data_source";
import {LoadOptions} from "devextreme/data/load_options";
import {ResultPage} from "@gipa/devextreme-to-spring";
import DocumentTypeMapping from "@/types/DocumentTypeMapping";
import DocumentTypeMappingConnector from "@/components/documentTypeMapping/DocumentTypeMappingConnector";
import DevExpress from "devextreme";
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from "devextreme/data/custom_store";


export const documentTypeMappingDSO: DataSourceOptions =  {
    store: new CustomStore({
        key: "id",
        byKey(key: string): Promise<DocumentTypeMapping> {
            return DocumentTypeMappingConnector.fetchDocumentTypeMappingById(key);
        },
        load(loadOptions: LoadOptions): Promise<ResultPage<DocumentTypeMapping>> {
            return DocumentTypeMappingConnector.getInstance().filter(loadOptions);
        }
    }),
    paginate: true,
    pageSize: 20,
}

const documentTypeMappingSource: DataSource = new DataSource({
    key: "self",
    load: function (loadOptions: LoadOptions): Promise<ResultPage<DocumentTypeMapping>> {
        return DocumentTypeMappingConnector.getInstance().filter(loadOptions);
    },
    insert(values): Promise<DocumentTypeMapping> {
        return DocumentTypeMappingConnector.getInstance().create(values);
    },
    update(object, value): Promise<DocumentTypeMapping> {
        return DocumentTypeMappingConnector.getInstance().update(value, object.href);
    }
})

export default documentTypeMappingSource;
