import {SyncDetails, SyncDetailsJSON} from "@/types/Transaction/Document/SyncDetails";
import {ConnectorFactory} from "@/util/ConnectorFactory";

export {fetchSyncDetails}

function fetchSyncDetails(transactionId: string): Promise<SyncDetails> {
    const traverson = ConnectorFactory.buildBaseClient();
    return new Promise((resolve, reject) => {
        traverson
            .follow('transactionDocumentSyncDetails', 'search', 'findByTransactionId')
            .withTemplateParameters({transactionId: transactionId})
            .getResource((error: any, data: SyncDetailsJSON) => {
                if (error) {
                    reject(error);
                    return;
                }
                resolve(
                    new SyncDetails(data)
                );
            });
    });

}
