










































import {Component, Vue} from "vue-property-decorator";
import {ViewLabel} from "@/store/types";
import {
    FLUENT_ICON_MATCHING_CREATION,
    FLUENT_ICON_PROCEED, FLUENT_ICON_REJECT,
    STATE_MATCHING_CREATION
} from "@/types/StateConst";
import DxButton from "devextreme-vue/button";
import DxDataGrid, {DxColumn} from "devextreme-vue/data-grid";
import {Partner} from "@/types/Partner";
import partnerSource, {remoteOperations} from "@/components/partner/PartnerListSource";
import {RemoteOperations} from "@/types/RemoteOperations";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getInitials} from "@/util/string.module";
import MatchingCreationStatusBar from "@/components/matchingCreation/MatchingCreationStatusBar.vue";
import {Agreement} from "@/types/Agreement";
import {State} from "vuex-class";
import AgreementConnector from "@/components/matching/AgreementConnector";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;
import DataSource = DevExpress.data.DataSource;
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {MATCHING_LIST_ROUTE_NAME, PARTNER_SELECTION_ROUTE_NAME} from "@/router";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";

@Component({
    components: {
        MatchingCreationStatusBar,
        StatusIcon,
        DxButton,
        DxColumn,
        DxDataGrid,
    },
    methods: {
        getInitials: getInitials,
    },
})

export default class PartnerSelection extends Vue {
    @State(state => state.agreement) agreement: Agreement | null | undefined;

    private readonly FLUENT_ICON_PROCEED = FLUENT_ICON_PROCEED;

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_MATCHING_CREATION,
        false,
        { name: PARTNER_SELECTION_ROUTE_NAME }
    );

    renderToggle: boolean = false;

    error: any = null;

    partners: DataSource = partnerSource;
    notFoundText: string = "Es liegen keine Partner vor."
    remotePartnerOperations: RemoteOperations = remoteOperations;

    public static readonly BASIC_VIEW_LABEL: ViewLabel = {
        primary: "Anfrage erstellen",
        secondary: false,
        icon: FLUENT_ICON_MATCHING_CREATION,
        cssClass: "matchingCreation",
    };

    dxColumns: dxDataGridColumn[] = [
        {
            caption: '',
            allowSorting: false,
            cssClass: "align-middle",
            allowFiltering: false,
            width: "52",
            dataType: "object",
            cellTemplate: "partnerIconTemplate"
        },
        {
            caption: 'Name',
            dataField: 'name',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
            allowFiltering: true,
        },
        {
            cellTemplate: "commandTemplate",
            allowSorting: false,
            allowFiltering: false,
            dataType: "object",
            alignment: "center",
            width: "49px",

        },
    ];

    private readonly cancelCommandButton: UFButtonOptions = new UFButtonOptions(
        () => {
            this.$store.commit("clearAgreement");
            this.$router.push({name: MATCHING_LIST_ROUTE_NAME});
        },
        "Erstellung abbrechen",
        FLUENT_ICON_REJECT,
        "Den Prozess zum Erstellen eines Matchings abbrechen"
    )

    mounted(): void {
        this.$store.commit("clearMatching");
        this.$store.commit("clearPartner");
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);

        this.$store.commit("updateViewLabel", PartnerSelection.BASIC_VIEW_LABEL);

        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.cancelCommandButton);

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);

        if (this.$store.state.agreement === null) {
            this.loadAgreement(this.$route.params.href);
        } else {
            this.handleAgreementIsLoaded();
        }
    }

    beforeDestroy(): void {
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.cancelCommandButton);
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    }

    handleRefresh(): void {
        this.loadAgreement(this.$route.params.href);
        this.partners.reload();
        this.refreshView();
    }

    proceedToVerification(partner: Partner): void {
        if (partner.partnerId != null) {
            this.$store.commit("selectPartner", partner);
            this.$router.push({
                name: "matchingRequest",
                params: {
                    href: this.$store.state.agreement.self,
                    partnerId: partner.partnerId,
                }
            });
        }
    }

    loadAgreement(selfLink: string): void {
        AgreementConnector.getInstance().loadFromHref(selfLink).then(
            agreement => {
                this.$store.commit("selectAgreement", agreement);
                this.handleAgreementIsLoaded();
            },
            error => {
                this.$store.commit("clearAgreement");
                defaultFetchErrorHandler(error);
                this.error = error;
            }
        );
    }

    handleAgreementIsLoaded(): void {
        this.$store.commit("setAgreementContextVisibility", true);
    }

    refreshView(): void {
        this.renderToggle = !this.renderToggle;
    }

}
