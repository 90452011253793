import MatchingConnector from '@/components/matching/MatchingConnector';
import {RemoteOperations} from "@/types/RemoteOperations";
import DataSource from 'devextreme/data/data_source';
import {ResultPage} from "@gipa/devextreme-to-spring";
import {Matching} from "@/types/Matching";

export const sortOptions: any = {
    avalId: "avalID",
    status: "avalState",
    customer: "client.contractPartner",
    material: "client.material",
    date: "requestedOn"
};

export const remoteOperations: RemoteOperations = {
    paging: true,
    sorting: true,
    filtering: true,
};

const matchingSource: DataSource = new DataSource({
    load: function (loadOptions): Promise<ResultPage<Matching>> {
        return MatchingConnector.getInstance().filter(loadOptions);
    }
});

export default matchingSource;
