











import Vue from "vue";
import {ViewLabel} from "@/store/types";
import {FLUENT_ICON_TRANSACTIONS} from "@/types/StateConst";
import Component from "vue-class-component";
import TransactionHistory from "@/components/transaction/TransactionHistory.vue";
import {DISABLE_CANDIS_EMBEDDED_MODE, ENABLE_CANDIS_EMBEDDED_MODE} from "@/types/MutationConst";
import {EventBus} from "@/main";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import TransactionDocumentSyncDetails from "@/components/transaction/TransactionDocumentSyncDetails.vue";


@Component({
    components: {
        TransactionHistory,
        TransactionDocumentSyncDetails,
    }
})
export default class TransactionHistoryView extends Vue {
    public static readonly VIEW_LABEL: Readonly<ViewLabel> = {
        primary: "Transaction History",
        secondary: false,
        icon: FLUENT_ICON_TRANSACTIONS,
        cssClass: "Transaction",
    };

    renderToggle: boolean = true;

    mounted(): void {
        this.$store.commit("clearMatching");
        this.$store.commit("clearAgreement");
        this.$store.commit("updateViewLabel", TransactionHistoryView.VIEW_LABEL);
        this.$store.commit(ENABLE_CANDIS_EMBEDDED_MODE);
        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(DISABLE_CANDIS_EMBEDDED_MODE);
    }

    handleRefresh() {
        this.renderToggle = !this.renderToggle;
    }

}
