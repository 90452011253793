
























import {Component, Vue} from "vue-property-decorator";
import * as TransactionHistoryConnector from "@/components/transaction/TransactionHistoryConnector";
import {HistoricTransactionDiff} from "@/types/Transaction/HistoricTransactionDiff";
import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
import DiffEntry from "@/components/transaction/DiffEntry.vue";
import {Transaction} from "@/types/Transaction/Transaction";
import {LOGIN_ROUTE_NAME} from "@/router";

@Component({
    components: {
        DiffEntry,
        DxLoadIndicator,
    },
    props: {
        transactionId: {
            type: String,
            required: true,
        },
    }
})
export default class TransactionHistory extends Vue {
    loading: boolean = true;
    error: any = null;
    historyDiffList: Array<HistoricTransactionDiff> = [];

    mounted(): void {
        TransactionHistoryConnector.fetchHistory(this.$props.transactionId)
            .then(this._handleFetchSuccess)
            .catch(this._handleFetchError)
            .finally(
                () => {this.loading = false;}
                );
    }

    _handleFetchSuccess(response: Transaction[]): void {
        this.historyDiffList = [];
        let previous: Transaction | null = null;
        for (const entry of response) {
            this.historyDiffList.push(new HistoricTransactionDiff(entry, previous));
            previous = entry;
        }
        this.historyDiffList.reverse();
        this.error = null;
    }

    _handleFetchError(error: any): void {
        if (error.httpStatus === 401) {
            this.$store.commit("loggedOut");
            this.$router.push({name: LOGIN_ROUTE_NAME, params: {originPath: this.$router.currentRoute.path}});
        } else {
            this.historyDiffList = [];
            this.error = error;
        }
    }
}

