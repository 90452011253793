import {getStringOrDefault} from "@/util/string.module";
import {AdditionalContent} from "@/types/AdditionalContent";

export {Matching};

export enum StatusEnum {
    "new" = 1,
    "matched" = 2,
    "cancelled" = 3,
}

const statusLocalization = new Map<string, string>();
statusLocalization.set("new", "neu");
statusLocalization.set("matched", "zugeordnet");
statusLocalization.set("cancelled", "abgebrochen");

class Matching {
    readonly status: StatusEnum;
    readonly id: string;
    readonly client: Role;
    readonly supplier: Role;
    readonly requestDate: Date;
    readonly requestedBy: string;
    readonly oAuthUserForSync: string;
    readonly self: string;
    readonly matchedAgreementLink: string;
    readonly isMatchable: boolean;
    readonly isAbortable: boolean;
    readonly isSupplier: boolean;
    readonly lastSync: Date | null;
    readonly lastUpdateAt: Date | null;
    readonly lastUpdateBy: string;
    readonly extendedInformation: Map<string, AdditionalContent>;
    requiredDocuments: string[];

    constructor(json: MatchingJSON) {
        this.status = json.avalState;
        this.id = json.avalId;
        this.client = json.client;
        this.supplier = json.supplier;
        this.requestDate = new Date(json.requestedOn);
        this.requestedBy = json.requestedBy;
        this.oAuthUserForSync = json.oauthUserForSync;
        this.self = json._links.self.href;
        if (json._links.matchedAgreement) {
            this.matchedAgreementLink = json._links.matchedAgreement.href;
        } else {
            this.matchedAgreementLink = "";
        }
        if (json.lastSync) {
            this.lastSync = new Date(json.lastSync);
        } else {
            this.lastSync = null;
        }
        if (json.lastUpdateAt) {
            this.lastUpdateAt = new Date(json.lastUpdateAt);
        } else {
            this.lastUpdateAt = null;
        }
        if (json.lastUpdateBy) {
            this.lastUpdateBy = json.lastUpdateBy;
        }else {
            this.lastUpdateBy = "";
        }
        this.isSupplier = json.isSupplier;

        this.extendedInformation = new Map<string, AdditionalContent>();
        for (const key of Object.keys(json.extendedInformation)) {
            const value: AdditionalContent = json.extendedInformation[key];
            this.extendedInformation.set(key, value);
        }
        if (json.requiredDocuments) {
            this.requiredDocuments = json.requiredDocuments;
        } else {
            this.requiredDocuments = [];
        }

        // calculated values
        this.isMatchable = (this.status === StatusEnum.new);
        this.isAbortable = (this.status !== StatusEnum.cancelled);
    }

    getStatusClass(): string {
        return "matchingStatus" + this.status;
    }

    getStatusClassBorder(): string {
        return "border" + this.status;
    }

    getBackgroundStatusClass(): string {
        return "backMatchingStatus"+this.status;
    }

    getStatusString(): string {
        return getStringOrDefault(statusLocalization.get(StatusEnum[this.status]), "unbekannt");
    }

    static fromJSON(object: any): Matching {
        return new Matching(object);
    }

}

type ClientStringKey = "contractPartner" | "performancePlace" | "material" | "materialUnit" | "containerType" | "serviceUnit" | "cycleRhythm";
type ClientBoolKey = "cycleInformation";
interface Client extends Record<ClientStringKey, string>, Record<ClientBoolKey, boolean>{}

interface MatchingJSON {
    avalId: string;
    avalState: number;
    _links: {
        self: {
            href: string;
        };
        matchedAgreement: {
            href: string;
        };
    };
    client: Role;
    supplier: Role;
    oauthUserForSync: string;
    requestedOn: string;
    requestedBy: string;
    lastSync: string;
    lastUpdateAt: string;
    lastUpdateBy: string;
    isSupplier: boolean;
    extendedInformation: { [name: string]: AdditionalContent };
    requiredDocuments: string[];
}
