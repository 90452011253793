

















































































import {Component, Vue} from "vue-property-decorator";
import DxDataGrid from "devextreme-vue/data-grid";
import {ViewLabel} from "@/store/types";
import {FLUENT_ICON_TRANSACTIONS, STATE_TRANSACTION} from "@/types/StateConst";
import transactionSource, {remoteOperations, sortOptions} from "@/components/transaction/TransactionListSource";
import {RemoteOperations} from "@/types/RemoteOperations";
import TransactionHistory from "@/components/transaction/TransactionHistory.vue";
import TimePeriodElement from "@/components/frame/TimePeriodElement.vue";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getDateString, getInitials} from "@/util/string.module";
import SyncStatusBadge from "@/components/transaction/SyncStatusBadge.vue";
import {Transaction} from "@/types/Transaction/Transaction";
import {calculateFilterForDates} from "@/util/filter.module";
import {PartnerLookupDSO} from "@/components/partner/PartnerLookupDSO";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {TRANSACTION_LIST_ROUTE_NAME} from "@/router";
import {CHANGE_BREADCRUMB} from "@/types/MutationConst";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import TransactionDocumentSyncDetails from "@/components/transaction/TransactionDocumentSyncDetails.vue";
import DxButton from "devextreme-vue/button";
import {confirm} from 'devextreme/ui/dialog';
import TransactionConnector from "@/components/transaction/TransactionConnector";
import {HalTransaction} from "@/types/Transaction/HalTransaction";
import DataSource = DevExpress.data.DataSource;
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;

@Component({
  components: {
    TransactionHistory,
    DxDataGrid,
    TimePeriodElement,
    StatusIcon,
    SyncStatusBadge,
    TransactionDocumentSyncDetails,
    DxButton,
  },
  methods: {
    getInitials: getInitials,
  },
})
export default class TransactionList extends Vue {

  public static readonly VIEW_LABEL: Readonly<ViewLabel> = {
    primary: "AvaL Transactions",
    secondary: false,
    icon: FLUENT_ICON_TRANSACTIONS,
    cssClass: "Transaction",
  };

  private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
      STATE_TRANSACTION,
      true,
      { name: TRANSACTION_LIST_ROUTE_NAME }
  );

  readonly loadingText: string = "Lade ...";

  private cancelSyncColumn: dxDataGridColumn =
    {
      cellTemplate: "cancelSync",
      allowSorting: false,
      cssClass: "align-middle",
      width: "52",
      allowResizing: false,
    };


  dxColumns: dxDataGridColumn[] = [
    {
      caption: '',
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "statusIconTemplate",
    },
    {
      caption: 'Transaction ID',
      dataField: "id",
      allowSorting: false,
      visible: false,
      cssClass: "align-middle avalIdColumn",
      filterOperations: ['contains'],
      calculateFilterExpression: (filterValue) => {
        return ["transactionIdString", "contains", filterValue];
      },
    },
    {
      caption: 'AvaL ID',
      allowSorting: false,
      visible: false,
      cssClass: "align-middle avalIdColumn",
      cellTemplate: "avalIdTemplate",
      filterOperations: ['contains'],
      calculateFilterExpression: (filterValue) => {
        return ["avalIdString", "contains", filterValue];
      },
    },
    {
      caption: "Af-Nr. (CANDIS)",
      cssClass: "align-middle",
      dataField: "orderNumberInternal",
      filterOperations: ['contains'],
      calculateSortValue: function (): string {
        return sortOptions.orderNumberSupplier;
      }
    },
    {
      caption: 'Status',
      allowSorting: true,
      cssClass: "align-middle",
      dataField: 'status',
      lookup: {
        dataSource: stateDataSourceConfig,
        valueExpr: "status",
        displayExpr: "name",
      },
    },
    {
      caption: "Af-Nr. (Fremdsystem)",
      dataField: "orderNumberExternal",
      cssClass: "align-middle",
      filterOperations: ['contains'],
      calculateSortValue: function (): string {
        return sortOptions.orderNumberClient;
      },
      visible: false,
    },
    {
      caption: "Synchronisation",
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "syncStateTemplate",
      dataField: 'syncState',
      lookup: {
        dataSource: syncStateDataSourceConfig,
        valueExpr: "status",
        displayExpr: "name",
      },
      calculateSortValue: function (): string {
        return sortOptions.lastUpdateAt;
      },
    },
    {
      caption: "Menge",
      dataField: "materialAmount",
      cssClass: "align-middle",
      allowFiltering: false,
    },
    {
      caption: "Servicemenge",
      dataField: "serviceAmount",
      cssClass: "align-middle",
      visible: false,
      filterOperations: ['='],
    },
    {
      caption: "Anzahl Container",
      dataField: "containerAmount",
      cssClass: "align-middle",
      visible: false,
      filterOperations: ['='],
    },
    {
      caption: "Ausführungsdatum",
      allowSorting: true,
      cssClass: "align-middle",
      dataField: "fulfillment",
      dataType: "date",
      filterOperations: ['=', '<', '>='],
      calculateCellValue: function (rowData: Transaction): string {
        if (rowData.fulfillment) {
          return getDateString(rowData.fulfillment);
        }
        return "";
      },
      calculateFilterExpression: function (filterValue) {
        return calculateFilterForDates(filterValue, "fulfillment");
      }
    },
    {
      caption: "Gewünschter Zeitraum",
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "requestedTimePeriodTemplate",
      calculateSortValue: function (): string {
        return sortOptions.operationStartTimestamp;
      },
    },
    {
      caption: "Geplanter Zeitraum",
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "planedTimePeriodTemplate",
      calculateSortValue: function (): string {
        return sortOptions.planedFulfillmentStartTimestamp;
      },
    },
    {
      name: "oAuthUserForSync",
      caption: 'oAuthUserForSync',
      allowSorting: true,
      cssClass: "align-middle",
      visible: false,
      // showInColumnChooser: state.auth.partnerViewVisible,
      dataField: 'oAuthUserForSync',
    },
    {
      name: "partner",
      caption: 'Partner',
      allowSorting: false,
      cssClass: "align-middle multiColumnCell",
      dataField: 'oAuthUserForSync',
      lookup: {
        dataSource: PartnerLookupDSO,
        valueExpr: 'gipaOAuthUser',
        displayExpr: 'name',
      },
    },
  ];

  // I could not figure out which object e ist, documentation:
  // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
  onCellPrepared(e: any) {
      // this code dynamically adds a css class to cells in column "Status" depending on the transaction status to color the text
    if (e.rowType === "data" && e.column.dataField === "status") {
      e.cellElement.classList.add("transactionStatus" + e.value);
    }
  }

  _getStatusBgCssClass(status: number): string {
    return "backTransactionStatus" + String(status);
  }

  readonly notFoundText: string = "Es liegen keine Transactions (zum gewählten Filter) vor.";

  error: any = null;
  transactions: DataSource = transactionSource;
  remoteTransactionOperations: RemoteOperations = remoteOperations;
  renderToggle: boolean = false;

  created(): void {
      this.dxColumns.push(this.cancelSyncColumn);
  }

  mounted(): void {
    this.$store.commit("clearMatching");
    this.$store.commit("clearAgreement");
    this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
    this.$store.commit("updateViewLabel", TransactionList.VIEW_LABEL);

    EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
  }

  beforeDestroy(): void {
    EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
  }

  handleRefresh(): void {
    this.transactions.reload();
    this.refreshView();
  }

  _expandRow(e: any): void {
      e.component.collapseAll(-1);
      if (!e.isExpanded) {
          e.component.expandRow(e.key);
      }
  }

  refreshView(): void {
    this.renderToggle = !this.renderToggle;
  }

  cancelButtonClick(transaction: HalTransaction): void {
    const result = confirm(
        "<div style='line-height: 2; font-family: Arial, Helvetica, sans-serif; color: red;'>" +
        "<p>Nach Abbruch der Synchronisation werden ausstehende Transaction-Updates / Statusänderungen" +
        "<br>nicht mehr an den Partner übergeben." +
        "<br>Möchten Sie die Synchronisation der AvaL Transaction wirklich abbrechen?</p>" +
        "<div style='display: flex; justify-content: center;'>" +
        "<span style='font-size: 50px; margin-top: 10px;' class='dx-icon dx-icon-warning'></span></div></div>",
        "Synchronisation abbrechen"
    );
    result.then((dialogResult): void => {
      if (dialogResult) {
        TransactionConnector.cancelTransaction(transaction)
            .then(() => this.transactions.reload())
            .catch(
                error => {
                  defaultFetchErrorHandler(error);
                  this.error = error;
                }
            );
      }
    });
  }

  cancelButtonvisability(transaction: HalTransaction): boolean {
    return !(transaction.synced || transaction.status == -1);
  }

}

const stateDataSourceConfig = {
  store: {
    type: 'array',
    data: Transaction.statusMappingArray,
    key: 'state'
  },
};
const syncStateDataSourceConfig = {
  store: {
    type: 'array',
    data: Transaction.syncStateMappingArray,
    key: 'syncState'
  },
};
