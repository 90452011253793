import Vue from 'vue'
import Vuex, {Store} from 'vuex';
import "gipa-universal-frame/dist/gipa-universal-frame.css";
import "./assets/globalCss/aval.scss"
import {AvalState} from "@/store/types";
import {mutations} from "@/store/mutations";
import {initialState} from "@/store/initialState";
import {AUTHENTICATED, LOGGED_OUT} from "@/types/MutationConst";
import {LocalStorageService} from "gipa-universal-frame/src/util/LocalStorageService";
import router, {LOGIN_ROUTE_NAME} from "@/router";
import App from "@/App.vue";
import vueHeadful from 'vue-headful';
import VueResizeObserver from "vue-resize-observer";
import deMessages from "devextreme/localization/messages/de.json";
import {loadMessages, locale} from "devextreme/localization";

// Needed for PWA
import './registerServiceWorker'

//DO NOT DELETE ->
//devextreme style to load typescript reference
/// <reference path="./dx.all.d.ts" />

// Init localization
loadMessages(deMessages);
locale(navigator.language);

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.component('vue-headful', vueHeadful);

// Vue Resize Observer
Vue.use(VueResizeObserver);

export const EventBus: Vue = new Vue();

export const store: Store<AvalState> = new Vuex.Store<AvalState>({
    state: initialState,
    mutations: mutations,
});

const storedJwt = LocalStorageService.retrieveToken();
if (storedJwt) {
    store.commit(AUTHENTICATED, storedJwt);
}

console.debug("Creating AvaL frontend application ...");
new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App)
}).$mount('#app');

export function defaultFetchErrorHandler(error: any) {
    if (error.httpStatus === 401) {
        store.commit(LOGGED_OUT);
        router.push({name: LOGIN_ROUTE_NAME, params: {originPath: router.currentRoute.path}});
    } else {
        console.error(error)
    }
}
