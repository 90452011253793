








import {Component, Vue} from "vue-property-decorator";
import {State} from "vuex-class";

@Component
export default class TenantHead extends Vue {
    @State(state => state.tenantContext.activeTenantName) tenantName: string | undefined;
    @State(state => state.viewLabel.primary) titleSuffix: string | undefined;
}


