









import {Component, Vue} from "vue-property-decorator";

@Component({
        components: {
        },
        props: {
            initials: {
                type: String,
                default: "??",
            },
            cssClass: {
                type: String,
                default: "",
            },
            filled: {
                type: Boolean,
                default: true,
            },
            synced: {
                type: Boolean,
                default: false,
            },
            hideSyncedDot: {
                type: Boolean,
                default: false,
            },
            canceled: {
              type: Boolean,
            }
        },
    })
    export default class StatusIcon extends Vue {

      syncedCssClass(): string {
        if (this.$props.synced && !this.$props.canceled) {
          return "syncDone";
        } else if (this.$props.canceled) {
          return "syncCanceled"
        } else {
          return "syncPending";
        }
      }

    }

