import {BackendProperties} from "@/types/BackendProperties";
import {BACKEND_BASE_URL, ConnectorFactory} from "@/util/ConnectorFactory";

const INFO_URI = BACKEND_BASE_URL + "/info"

export {getBackendProperties}

function getBackendProperties(): Promise<BackendProperties> {
    const builder = ConnectorFactory.buildBaseClient(INFO_URI);
    return new Promise((resolve, reject) => {
        builder.get(function (error: any, response: any) {
            if (error) {
                reject(error);
            } else {
                const body = JSON.parse(response.body);
                if (body.error) {
                    reject(body.error);
                } else {
                    resolve(BackendProperties.fromJSON(body));
                }
            }
        });
    });
}
