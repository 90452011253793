import {DxFilter} from "@gipa/devextreme-to-spring";

export {calculateFilterForDates}

function calculateFilterForDates(filterValue: string | Array<string>, key: string): Array<DxFilter> {
    let from: Date | undefined;
    let until: Date | undefined;
    if (Array.isArray(filterValue)) {
        if (filterValue[0] && filterValue[1]) {
            from = new Date(filterValue[0]);
            until = new Date(filterValue[1]);
        } else {
            // only one date for "between" filtering is picked, do not set a filter, wait for second date
            return [];
        }
    } else {
        from = new Date(filterValue);
        until = new Date(from);
        until.setDate(from.getDate() + 1);
    }
    return [
        [key, '>=', from.toISOString()],
        [key, '<', until.toISOString()],
    ];
}
