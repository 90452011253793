import Vue from 'vue'
import VueRouter, {Route} from 'vue-router'
import MatchingList from '@/components/matching/MatchingList.vue'
import AgreementSelection from "@/components/matching/AgreementSelection.vue";
import Login from "@/components/login/Login.vue";
import {store} from "@/main";
import Home from "@/components/home/Home.vue";
import TransactionList from "@/components/transaction/TransactionList.vue";
import AgreementDetails from "@/components/matching/AgreementDetails.vue";
import Partner from "@/components/partner/PartnerList.vue";
import PartnerDetails from "@/components/partner/PartnerDetails.vue";
import MatchableAgreementSelection from "@/components/matchingCreation/MatchableAgreementSelection.vue";
import PartnerSelection from "@/components/matchingCreation/PartnerSelection.vue";
import MatchingRequest from "@/components/matchingCreation/MatchingRequest.vue";
import TransactionHistoryView from "@/components/transaction/TransactionHistoryView.vue";
import DocumentTypeMapping from "@/components/documentTypeMapping/DocumentTypeMapping.vue";
import {SWITCH_TO_TENANT} from "@/types/MutationConst";

Vue.use(VueRouter);

export const LOGIN_ROUTE_NAME = "login";
export const HOME_ROUTE_NAME: string = "home";
export const AGREEMENT_DETAILS_ROUTE_NAME: string = "agreementDetails";
export const AGREEMENT_SELECTION_ROUTE_NAME = "agreementSelection";
export const MATCHING_LIST_ROUTE_NAME = "matchingList";
export const CREATE_MATCHING_AGREEMENT_SELECTION_ROUTE_NAME = "matchableAgreementSelection";
export const MATCHING_REQUEST_ROUTE_NAME = "matchingRequest";
export const PARTNER_SELECTION_ROUTE_NAME = "partnerSelection";
export const PARTNER_DETAILS_ROUTE_NAME = "partnerDetails";
export const PARTNER_LIST_ROUTE_NAME = "partner";
export const TRANSACTION_LIST_ROUTE_NAME = "transactionList";
export const TRANSACTION_HISTORY_ROUTE_NAME = "transactionHistory";
export const DOCUMENT_TYPE_MAPPING_ROUTE_NAME = "documetnTypeMapping";

export const ADMIN_ROUTES: string[] = [PARTNER_LIST_ROUTE_NAME, PARTNER_DETAILS_ROUTE_NAME, DOCUMENT_TYPE_MAPPING_ROUTE_NAME];

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: '/login',
        name: LOGIN_ROUTE_NAME,
        component: Login,
    },
    {
        path: '/home',
        name: HOME_ROUTE_NAME,
        component: Home,
    },
    {
        path: '/matchings',
        name: MATCHING_LIST_ROUTE_NAME,
        component: MatchingList,
    },
    {
        path: '/matchings/:id/agreements',
        name: AGREEMENT_SELECTION_ROUTE_NAME,
        component: AgreementSelection
    },
    {
        path: '/matchings/:id/agreement',
        name: AGREEMENT_DETAILS_ROUTE_NAME,
        component: AgreementDetails
    },
    {
        path: '/transactions',
        name: TRANSACTION_LIST_ROUTE_NAME,
        component: TransactionList
    },
    {
        path: '/partner',
        name: PARTNER_LIST_ROUTE_NAME,
        component: Partner
    },
    {
        path: '/partnerDetails/:id',
        name: PARTNER_DETAILS_ROUTE_NAME,
        component: PartnerDetails
    },
    {
        path: '/matchingCreation/matchableAgreementSelection',
        name: CREATE_MATCHING_AGREEMENT_SELECTION_ROUTE_NAME,
        component: MatchableAgreementSelection
    },
    {
        path: '/matchingCreation/partnerSelection/:href',
        name: PARTNER_SELECTION_ROUTE_NAME,
        component: PartnerSelection
    },
    {
        path: '/matchingCreation/matchingRequest/:href/:partnerId',
        name: MATCHING_REQUEST_ROUTE_NAME,
        component: MatchingRequest
    },
    {
        path: '/transactions/:id/history',
        name: TRANSACTION_HISTORY_ROUTE_NAME,
        component: TransactionHistoryView
    },
    {
        path: '/documentTypeMapping',
        name: DOCUMENT_TYPE_MAPPING_ROUTE_NAME,
        component: DocumentTypeMapping
    },
];

const router: VueRouter = new VueRouter({routes});

function shouldRedirectToLogin(loggedIn: string, to: Route): boolean {
    return !loggedIn && !to.path.includes("login");
}

router.beforeEach((to, from, next) => {

    const loggedIn = store.state.auth.username;
    console.debug("Before routing user '" + (loggedIn || "unknown") + "' to path '" + to.path + "'.");

    if(loggedIn && to.name === LOGIN_ROUTE_NAME) {
        console.debug("Already logged in, will redirect to /home");
        next({name: HOME_ROUTE_NAME});
        return;
    }

    // use "tenant"-url-parameter as desired tenant role (if present)
    if (to.query.tenant && typeof to.query.tenant === 'string') {
        console.debug("will redirect to tenant: " + to.query.tenant);
        store.commit(SWITCH_TO_TENANT, to.query.tenant);
        next({path: to.path});
        return;
    }

    if (shouldRedirectToLogin(loggedIn, to)) {
        // Caching origin destination for redirection after successful login
        store.state.router.redirectedFrom = to.path;
        console.debug("Redirecting to login page");
        next({name: LOGIN_ROUTE_NAME, params: {originPath: to.path}});
        return;
    }

    if(!store.state.auth.adminViewsVisible && to.name && ADMIN_ROUTES.includes(to.name)){
        console.log("prevent routing to restricted view, redirect to home ...");
        next({name: HOME_ROUTE_NAME});
        return;
    }

    console.log("routing checks are done will continue to path: " + to.path);
    next();
});

export default router;
