import DataSource, {DataSourceOptions} from "devextreme/data/data_source";
import {LoadOptions} from "devextreme/data/load_options";
import {ResultPage} from "@gipa/devextreme-to-spring";
import DocumentType from "@/types/DocumentType";
import DocumentTypeConnector from "@/components/documentTypeMapping/DocumentTypeConnector";
import CustomStore from "devextreme/data/custom_store";

export const documentTypeDSO: DataSourceOptions =  {
    store: new CustomStore({
        key: "avalId",
        byKey(key: string): Promise<DocumentType> {
            return DocumentTypeConnector.fetchDocumentTypeById(key);
        },
        load(loadOptions: LoadOptions): Promise<ResultPage<DocumentType>> {
            return DocumentTypeConnector.getInstance().filter(loadOptions);
        }
    }),
    paginate: true,
    pageSize: 20,
}


const documentTypeSource: DataSource = new DataSource({
    load: function (loadOptions: LoadOptions): Promise<ResultPage<DocumentType>> {
        return DocumentTypeConnector.getInstance().filter(loadOptions);
    }
});

export default documentTypeSource;
