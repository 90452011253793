import DataSource from "devextreme/data/data_source";
import AgreementConnector from "@/components/matching/AgreementConnector";
import {RemoteOperations} from "@/types/RemoteOperations";
import {ResultPage} from "@gipa/devextreme-to-spring";
import {Agreement} from "@/types/Agreement";

export const remoteOperations: RemoteOperations = {
    paging: true,
    sorting: true,
    filtering: true,
};

const agreementDataSource: DataSource = new DataSource({
    load: function (loadOptions): Promise<ResultPage<Agreement>> {
        return AgreementConnector.filterMatchableAgreements(loadOptions);
    }
});

export default agreementDataSource;
