






































import Vue from "vue";
import {Component} from "vue-property-decorator";
import DxDataGrid, {DxEditing, DxForm, DxPopup} from "devextreme-vue/data-grid";
import DataSource from "devextreme/data/data_source";
import documentTypeMappingSource from "@/components/documentTypeMapping/DocumentTypeMappingSource"
import {documentTypeDSO} from "@/components/documentTypeMapping/DocumentTypeSource"
import {dxDataGridColumn} from "devextreme/ui/data_grid";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {EventBus} from "@/main";
import {FLUENT_ICON_DOCUMENT_TYPE_MAPPINGS, STATE_DOCUMENT_TYPE_MAPPINGS} from "@/types/StateConst";
import {ViewLabel} from "@/store/types";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {DOCUMENT_TYPE_MAPPING_ROUTE_NAME} from "@/router";
import {CHANGE_BREADCRUMB} from "@/types/MutationConst";
import {DxItem} from "devextreme-vue/form";
import {DxSelectBox} from "devextreme-vue";

@Component( {
    components: {
        DxDataGrid,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxSelectBox
    }
})
export default class DocumentTypeMapping extends Vue {
    private renderToggle: boolean = false;
    private documentTypeMappingSource: DataSource = documentTypeMappingSource;

    private readonly notFoundText: string = "Keine Dokument Type Mappings konfiguriert"

    public static readonly VIEW_LABEL: Readonly<ViewLabel> = {
        primary: "Dokument Typ Mapping",
        secondary: false,
        icon: FLUENT_ICON_DOCUMENT_TYPE_MAPPINGS,
        cssClass: "documentTypeMapping",
    };

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_DOCUMENT_TYPE_MAPPINGS,
        true,
        { name: DOCUMENT_TYPE_MAPPING_ROUTE_NAME }
    );

    private dxColumns: dxDataGridColumn[] = [
        {
            caption: 'AvaL Dokument Typ',
            allowSorting: true,
            cssClass: "align-middle",
            dataField: "avalTypeId",
            lookup: {
                dataSource: documentTypeDSO,
                valueExpr: "avalId",
                displayExpr: "description",

            }
        },
        {
            caption: 'DMS Dokument Typ',
            allowSorting: true,
            cssClass: "align-middle",
            dataField: "dmsTypeId",
        },
    ]

    mounted(): void {
        this.$store.commit("clearMatching");
        this.$store.commit("clearAgreement");
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
        this.$store.commit("updateViewLabel", DocumentTypeMapping.VIEW_LABEL);
        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    }

    handleRefresh(): void {
        this.renderToggle = !this.renderToggle;
    }
}
