import {formatDate} from "devextreme/localization";

const DATE_FORMAT = "dd.MM.yyyy";
const HOUR_MINUTE_FORMAT = "HH:mm";
const DATE_TIME_FORMAT = DATE_FORMAT + " " + HOUR_MINUTE_FORMAT;
const SECONDS_FORMAT_SUFFIX = ":ss";
const DAY_DATE_FORMAT = "EEEE, dd. MMMM";

export function getStringOrDefault(value: string | undefined, defaultValue: string): string {
    return value != undefined ? value : defaultValue;
}

export function getInitials(full: string): string {
    let result: string = "??";

    const split: string[] = full.split(" ");

    if (split[0]) {
        result = split[0].charAt(0);
        if (split[1]) {
            result += split[1].charAt(0);
        }
    }
    return result;
}

export function getDateString(date: Date): string {
    return formatDate(date, DATE_FORMAT);
}

export function getTimeString(date: Date, withSeconds: boolean = true): string {
    let format = HOUR_MINUTE_FORMAT;
    if (withSeconds) {
        format += SECONDS_FORMAT_SUFFIX;
    }
    return formatDate(date, format);
}

export function getDateTimeString(date: Date, withSeconds: boolean = true): string {
    let format = DATE_TIME_FORMAT;
    if (withSeconds) {
        format += SECONDS_FORMAT_SUFFIX;
    }
    return formatDate(date, format);
}

export function getDayDateString(date: Date): string {
    return formatDate(date, DAY_DATE_FORMAT);
}

export function constructDateFromString(dateString: string | null | undefined): Date | null {
    if (dateString) {
        return new Date(dateString);
    }
    return null;
}
