import {Partner, PartnerJSON} from "@/types/Partner";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {PropertyMapping, GenericEntityConnector} from "@gipa/devextreme-to-spring";

export default class PartnerConnector {
    private static readonly propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>([
        ["authentication","authentication.authenticationType"]
    ]));

    static connector: GenericEntityConnector<Partner> = new GenericEntityConnector<Partner>(
        PartnerConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "partners",
        Partner,
        "name"
    );

    public static getInstance(): GenericEntityConnector<Partner> {
        return PartnerConnector.connector;
    }

    public static isGipaOAuthUserUnique(gipaOAuthUser: string): Promise<boolean> {
        const traverson = ConnectorFactory.buildBaseClient();
        return new Promise((resolve, reject) => {
            traverson
                .follow('partners', 'search', 'hasGipaOAuthUser')
                .withTemplateParameters({gipaOAuthUser: gipaOAuthUser})
                .getResource((error: any, response: boolean) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    resolve(!response);
                });
        });
    }

    public static fetchPartnerByOAuthUserForSync(oAuthUserForSync: string): Promise<Partner> {
        const traverson = ConnectorFactory.buildBaseClient();
        return new Promise<Partner>((resolve, reject) => {
            traverson
                .follow('partners', 'search', 'findByGipaOAuthUser')
                .withTemplateParameters({gipaOAuthUser: oAuthUserForSync})
                .getResource((error: any, response: PartnerJSON) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    resolve(Partner.fromJSON(response));
                })
        })
    }

}
