
export {BasicAuthentication};

class BasicAuthentication{

    userName: string;
    password: string;

    constructor(userName: string, password: string) {
        this.userName = userName;
        this.password = password;
    }

    static fromJSON(json: BasicAuthenticationJSON){
        return new BasicAuthentication(json.userName, json.password);
    }
}

interface BasicAuthenticationJSON {
    userName: string;
    password: string;
}