import {GenericEntityConnector, PropertyMapping} from "@gipa/devextreme-to-spring";
import DocumentType, {DocumentTypeJson} from "@/types/DocumentType";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {defaultFetchErrorHandler} from "@/main";

export default class DocumentTypeConnector {
    private static readonly propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>());

    static connector: GenericEntityConnector<DocumentType> = new GenericEntityConnector<DocumentType>(
        DocumentTypeConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "documentTypes",
        DocumentType,
        "id"
    );

    public static getInstance(): GenericEntityConnector<DocumentType> {
        return DocumentTypeConnector.connector;
    }

    public static fetchDocumentTypeById(key: string): Promise<DocumentType> {
        const traverson = ConnectorFactory.buildBaseClient();
        return new Promise<DocumentType>((resolve, reject) => {
            traverson
                .follow('documentTypes', 'search', 'getById')
                .withTemplateParameters({id: key})
                .getResource((error: any, response: DocumentTypeJson) => {
                    if (error) {
                        defaultFetchErrorHandler(error);
                        reject();
                    }
                    resolve(new DocumentType(response));
                })
        })
    }

}
