















import {Component, Vue} from "vue-property-decorator";
import {TimePeriod} from "@/types/Transaction/TimePeriod";
import TimePeriodElement from "@/components/frame/TimePeriodElement.vue";

@Component({
    components: {
        TimePeriodElement
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: [String, TimePeriod],
            required: true,
        },
        valueCssClass: {
            type: String,
            default: "",
        },
    }
})
export default class SingleDiffElement extends Vue {
    _isTimePeriod(): boolean {
        return this.$props.value instanceof TimePeriod
    }

}

