export {Agreement, AgreementJSON};

class Agreement {
    readonly title: string;
    readonly description: string;
    readonly positions: string[];
    readonly candisId: string;
    readonly shorthand: string;
    readonly customer: string;
    readonly customerNo: string;
    readonly location: string;
    readonly summary: Role;
    readonly self: string;

    constructor(json: AgreementJSON) {
        this.title = json.title;
        this.description = json.description;
        this.positions = json.positions;
        this.candisId = json.referenceKey;
        this.shorthand = json.customer.shorthand;
        this.customer = json.customer.name;
        this.customerNo = json.customer.number;
        this.location = Agreement.extractLocation(json);
        this.summary = json.summary;
        this.self = json._links.self.href;
    }

    getBackgroundStatusClass(): string {
        return "backMatchingStatus1";
    }

    static fromJSON(object: any): Agreement {
        return new Agreement(object);
    }

    private static extractLocation(json: AgreementJSON): string {
        if (!json.location) {
            return "";
        }
        let result: string = Agreement._nullSaveTrim(json.location.name, ", ");
        result += Agreement._nullSaveTrim(json.location.street, " ");
        result += Agreement._nullSaveTrim(json.location.number, ", ");
        result += Agreement._nullSaveTrim(json.location.postalCode, " ");
        result += Agreement._nullSaveTrim(json.location.city, "");
        return result;
    }

    private static _nullSaveTrim(text: string | null, ends: string): string {
        if (text) {
            return text.trim() + ends;
        }
        return "";
    }

}


interface AgreementJSON {
    title: string;
    description: string;
    referenceKey: string;
    positions: string[];
    summary: Role;
    customer: {
        number: string;
        shorthand: string;
        name: string;
    };
    location: {
        name: string;
        street: string;
        number: string;
        postalCode: string;
        city: string;
        country: string;
    };
    _links: {
        self: {
            href: string;
        };
    };
}
