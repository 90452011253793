
































































































import {Component, Vue} from "vue-property-decorator";
import {authenticate} from "@/store/authentication.module";
import {Authentication} from "gipa-universal-frame/src/types/Authentication";
import {State} from "vuex-class";
import {DxButton} from 'devextreme-vue';
import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box'

const PASSWORD_MODE_HIDDEN = "password";
const PASSWORD_MODE_VISIBLE = "text";

@Component({
  components: {
    DxButton,
    DxTextBox,
    DxTextBoxButton,
  }
})

export default class Login extends Vue {
  @State((UFState) => UFState.auth) stateAuth: Authentication | undefined;

  login: string = "Anmelden";
  reset: string = "Zurücksetzen";
  type: string = "default";
  resetVisible: boolean = false;
  userIcon: string = "ms-icon ic_fluent_person_48_regular";
  username: string = "";
  password: string = "";
  pwReset: string = "Passwort zurücksetzen";
  passwordMode: string = PASSWORD_MODE_HIDDEN;

  passwordButton: any = {
    icon: "img/icons/password_visible.png",
    type: "default",
    onClick: () => {
      this.setPasswordVisible()
    }
  };

  mounted(): void {
    if (!this.$store) {
      console.log("[ERROR]: where is my store?");
    }
  }

  performSubmit(): void {
    if (!this.username) {
      this.$store.commit("updateAuthenticationDisplayMessage", "Bitte geben Sie einen Benutzernamen ein!");
      return;
    }
    if (!this.password) {
      this.$store.commit("updateAuthenticationDisplayMessage", "Bitte geben Sie Ihr Passwort ein!");
      return;
    }
    authenticate(this.username, this.password, this.$route.params.originPath);
  }

  showReset(): void {
    this.resetVisible = true;
  }

  pwResetBack(): void {
    this.resetVisible = false;
  }

  performReset(): void {
    alert("Password reset not yet implemented!")
  }

  setPasswordVisible(): void {
    if (this.passwordMode === PASSWORD_MODE_HIDDEN) {
      this.passwordMode = PASSWORD_MODE_VISIBLE;
    } else {
      this.passwordMode = PASSWORD_MODE_HIDDEN;
    }
  }
}

