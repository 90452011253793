import {AvalAuthentication, AvalState} from "@/store/types";
import {APP_NAME, FLUENT_ICON_HOME, FLUENT_ICON_QUESTIONMARK, STATE_TOOLNAME} from "@/types/StateConst";
import {TenantContext} from "@/util/TenantContext";
import { AVAL_FE_VERSION } from '@/app-config';
import { UFButtonOptions } from 'gipa-universal-frame/src/types/UFButtonOptions';
import notify from "devextreme/ui/notify";
import Breadcrumb from 'gipa-universal-frame/src/types/Breadcrumb';
import router, {LOGIN_ROUTE_NAME} from "@/router";
import { universalFrameVersion } from "gipa-universal-frame/src/app-config";
import { alert } from "devextreme/ui/dialog";
import {store} from "@/main";
import {BACKEND_BASE_URL} from "@/util/ConnectorFactory";
import {LOGGED_OUT} from "@/types/MutationConst";

export const initialState: AvalState = {
    accountButton:  new UFButtonOptions(
        () => {
          notify({msg: "Funktion nicht verfügbar", width: "auto",}, "warning", 900);
        },
        "",
        "ms-icon ic_fluent_person_24_regular",
        "Nutzerprofil"
    ),
    agreement: null,
    agreementContextVisible: false,
    appMenuButtons: [],
    auth: new AvalAuthentication(),
    backendUrl: BACKEND_BASE_URL,
    backendVersion: "unbekannt",
    commandBarElements: [],

    logoutButton: new UFButtonOptions(
        () => {
            store.commit(LOGGED_OUT);
            router.push({ name: LOGIN_ROUTE_NAME});
        },
        "",
        "ms-icon ic_fluent_sign_out_24_regular",
        "Abmelden"
    ),
    matching: null,
    matchingContextVisible: false,
    navigationButtons: [],
    partner: null,
    router: {
        redirectedFrom: ""
    },
    showBaseHeader: true,
    showNavigationBar: true,
    showCommandBar: true,
    showToolHeaderBar: true,
    tenantContext: new TenantContext(),
    tenantSelectBox: null,
    tool: {
        toolName: APP_NAME,
        toolSubToolName: STATE_TOOLNAME,
        breadcrumb: new Breadcrumb(),
    },
    toolVersion: AVAL_FE_VERSION,
    utilityMenuElements: [
        new UFButtonOptions(
            () => {
                alert(
                    "<h3>Verwendete Komponenten</h3>" +
                    "<p> AvaL Frontend - " +
                    AVAL_FE_VERSION +
                    "<p> AvaL Backend - " +
                    store.state.backendVersion +
                    "<p> GIPA Universal Frame - " +
                    universalFrameVersion,
                    "AvaL Frontend"
                );
            },
            "",
            FLUENT_ICON_QUESTIONMARK,
            "Produkt Informationen"
        ),
    ],
    viewLabel: {
        primary: "Dashboard",
        secondary: false,
        icon: FLUENT_ICON_HOME,
        cssClass: "Home",
    },
};
