import {HalTransaction} from "@/types/Transaction/HalTransaction";
import {BACKEND_BASE_URL, ConnectorFactory} from "@/util/ConnectorFactory";
import {GenericEntityConnector, PropertyMapping} from "@gipa/devextreme-to-spring";

export default class TransactionConnector {
    static propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>([
        ["status", "state"],
        ["synced", "lastSync"],
        ["fulfillment", "fulfillmentTimestamp"]
    ]));

    static connector: GenericEntityConnector<HalTransaction> = new GenericEntityConnector<HalTransaction>(
        TransactionConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "transactions",
        HalTransaction,
        "lastUpdateAt",
        true
    );

    public static getInstance(): GenericEntityConnector<TransactionConnector> {
        return TransactionConnector.connector;
    }

    public static cancelTransaction(transaction: HalTransaction): Promise<void> {

        return new Promise((resolve, reject) => {
            ConnectorFactory
                .buildBaseClient(BACKEND_BASE_URL + "/transactions/" + transaction.id + "/synchronization/cancel")
                .post(transaction, (err: any, res: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(res);
                    }
                });
        });

    }

}
