export {WeeklyStackBarStats};

class WeeklyStackBarStats {

    readonly createdDay: string;
    readonly dayOfWeek: number;
    readonly week: string;
    readonly total: number;

    constructor(json: WeeklyStackBarStatsJSON) {
        this.createdDay = json.createdDay;
        this.dayOfWeek = json.dayOfWeek;
        this.week = json.week.toString();
        this.total = json.total;

    }

    static fromJSON(object: any): WeeklyStackBarStats {
        return new WeeklyStackBarStats(object);
    }

}

interface WeeklyStackBarStatsJSON {
    createdDay: string;
    dayOfWeek: number;
    week: number;
    total: number;
}
