import {Tenant} from "@/util/TenantContext";

export {BackendProperties}

class BackendProperties {
    backendVersion: string;
    existingTenants: Tenant[] = [];

    constructor(backendVersion: string, tenantInfoArray: TenantInfo[]) {
        this.backendVersion = backendVersion;
        for (const item of tenantInfoArray) {
            this.existingTenants.push(new Tenant(item.role, item.displayName));
        }
    }

    static fromJSON(json: BackendPropertiesJSON) {
        return new BackendProperties(json.backendVersion, json.tenantInfoList);
    }
}

class TenantInfo {
    displayName: string;
    role: string;

    constructor(displayName: string, role: string) {
        this.displayName = displayName;
        this.role = role;
    }
}

interface BackendPropertiesJSON {
    backendVersion: string;
    tenantInfoList: TenantInfo[];
}