//Store
import {Matching} from "@/types/Matching";
import {Partner} from "@/types/Partner";
import {Agreement} from "@/types/Agreement";
import {TenantContext} from "@/util/TenantContext";
import {UniversalFrameState} from "gipa-universal-frame/src/types/UniversalFrameState";
import {Authentication} from "gipa-universal-frame/src/types/Authentication";

export class AvalAuthentication extends Authentication {

    adminViewsVisible: boolean = false;

    get jwt(): string {
        return this._jwt;
    }

    set jwt(value: string) {
        super.jwt = value;
        this.adminViewsVisible = this._roles.includes("ROLE_ADMIN");
    }

}

export interface AvalState extends UniversalFrameState {
    viewLabel: ViewLabel;
    matching: Matching | null;
    matchingContextVisible: boolean;
    backendUrl: string; // Override to signal that there is always a backendUrl present
    // FIXME Maybe we should use vuex-router-sync instead of proprietary type???
    router: Router;
    partner: Partner | null;
    agreement: Agreement | null;
    agreementContextVisible: boolean;
    showToolHeaderBar: boolean;
    backendVersion: string;
    tenantContext: TenantContext;
    auth: AvalAuthentication;
}

export interface ViewLabel {
    primary: string;
    secondary: string | false;
    icon: string;
    cssClass: string;
}

export interface Router {
    redirectedFrom: string;
}
