


















































































































import {Component, Vue} from "vue-property-decorator";
import {Matching, StatusEnum} from '@/types/Matching';
import DxDataGrid from 'devextreme-vue/data-grid';
import {
    FLUENT_ICON_DETAIL,
    FLUENT_ICON_MATCHING_CREATION,
    FLUENT_ICON_MATCHINGS,
    FLUENT_ICON_PROCEED,
    FLUENT_ICON_REJECT,
    STATE_AGREEMENT_DETAILS,
    STATE_AGREEMENT_SELECTION,
    STATE_MATCHING
} from "@/types/StateConst";
import {ViewLabel} from "@/store/types";
import {RemoteOperations} from "@/types/RemoteOperations";
import DataSource from 'devextreme/data/data_source';
import DxButton from "devextreme-vue/button";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getDateTimeString, getInitials} from "@/util/string.module";
import PrimarySecondaryElement from "@/components/frame/PrimarySecondaryElement.vue";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import MatchingConnector from "@/components/matching/MatchingConnector";
import DxPopup from "devextreme-vue/popup";
import matchingSource, {remoteOperations, sortOptions} from "@/components/matching/MatchingListSource";
import {PartnerLookupDSO} from "@/components/partner/PartnerLookupDSO";
import {calculateFilterForDates} from "@/util/filter.module";
import {AGREEMENT_DETAILS_ROUTE_NAME, AGREEMENT_SELECTION_ROUTE_NAME, MATCHING_LIST_ROUTE_NAME} from "@/router";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;

@Component({
  components: {
    PrimarySecondaryElement,
    DxDataGrid,
    DxButton,
    StatusIcon,
    DxPopup,
  },
  methods: {
    getInitials: getInitials,
    getDateTimeString: getDateTimeString,
  },
})
export default class MatchingList extends Vue {

  public static readonly VIEW_LABEL: Readonly<ViewLabel> = {
    primary: "AvaL Matchings",
    secondary: false,
    icon: FLUENT_ICON_MATCHINGS,
    cssClass: "Matching",
  };

  private readonly createMatchingCommandButton: UFButtonOptions = new UFButtonOptions(
      () => {
        this.proceedToCreation();
      },
      "Matchinganfrage erstellen",
      FLUENT_ICON_MATCHING_CREATION,
      "Matchinganfrage erstellen"
  );


  private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
      STATE_MATCHING,
      true,
      { name: MATCHING_LIST_ROUTE_NAME }
  );

  readonly loadingText: string = "Lade ...";

  iconProceed: string = FLUENT_ICON_PROCEED;
  iconReject: string = FLUENT_ICON_REJECT;
  iconDetail: string = FLUENT_ICON_DETAIL;

  oAuthUserForSyncFilterValue?: string;
  statusFilterValue?: StatusEnum;
  isSupplierFilterValue?: string;

  data(): any {
    return {
      MatchingList,
      StatusEnum
    }
  }

  dxColumns: dxDataGridColumn[] = [
    {
      caption: '',
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "statusIconTemplate",
      width: "52",
      allowResizing: false,
    },
    {
      caption: 'AvaL ID',
      allowSorting: false,
      cssClass: "align-middle avalIdColumn ellipsisCell",
      filterOperations: ['contains'],
      dataField: "id",
      calculateFilterExpression: (filterValue) => {
        return ["avalIdString", "contains", filterValue];
      },
    },
    {
      caption: 'Status',
      allowSorting: true,
      // onCellPrepared() is used to add status dependent css-class for coloring
      cssClass: "align-middle avalStatusColumn",
      dataField: "status",
      width: "120",
      filterValue: this.statusFilterValue,
      lookup: {
        dataSource: {
          store: {
            type: "array",
            data: [
              {status: StatusEnum.new, label: "neu"},
              {status: StatusEnum.matched, label: "zugeordnet"},
              {status: StatusEnum.cancelled, label: "abgebrochen"}
            ],
            key: "status"
          },
        },
        valueExpr: "status",
        displayExpr: "label",
      },
    },
    {
      caption: 'Kunde',
      allowSorting: true,
      cssClass: "align-middle customerColumn multiColumnCell",
      cellTemplate: "customerTemplate",
      dataField: "client.contractPartner",
      allowFiltering: true,
      filterOperations: ['contains'],
      calculateSortValue: function (): string {
        return sortOptions.customer;
      },
    },
    {
      caption: 'Material',
      dataField: 'client.material',
      cssClass: "align-middle multiColumnCell",
      allowFiltering: true,
      filterOperations: ['contains'],
    },
    {
      caption: 'Container',
      allowSorting: true,
      cssClass: "align-middle multiColumnCell",
      dataField: "client.containerType",
      allowFiltering: true,
      filterOperations: ['contains'],
      calculateSortValue: function (): string {
        return sortOptions.container;
      },
    },
    {
      name: "isSupplier_abbreviation",
      caption: 'AN/AG',
      dataField: 'isSupplier',
      allowSorting: false,
      cssClass: "align-middle",
      filterValue: this.isSupplierFilterValue,
      lookup: {
        dataSource: {
          store: {
            type: "array",
            data: [
              {isSupplier: "true", label: "AN"},
              {isSupplier: "false", label: "AG"}
            ],
            key: "isSupplier"
          },
        },
        valueExpr: "isSupplier",
        displayExpr: "label",
      },
    },
    {
      name: "isSupplier_full",
      caption: 'Auftragnehmer/Auftraggeber',
      dataField: 'isSupplier',
      allowSorting: false,
      cssClass: "align-middle",
      alignment: "center",
      visible: false,
      lookup: {
        dataSource: {
          store: {
            type: "array",
            data: [
              {isSupplier: "true", label: "Auftragnehmer"},
              {isSupplier: "false", label: "Auftraggeber"}
            ],
            key: "isSupplier"
          },
        },
        valueExpr: "isSupplier",
        displayExpr: "label",
      },
    },
    {
      caption: 'Partner',
      allowSorting: false,
      cssClass: "align-middle multiColumnCell",
      dataField: 'oAuthUserForSync',
      filterValue: this.oAuthUserForSyncFilterValue,
      lookup: {
        dataSource: PartnerLookupDSO,
        valueExpr: 'gipaOAuthUser',
        displayExpr: 'name',
      },
    },
    {
      caption: 'Datum der Anfrage',
      allowSorting: true,
      cssClass: "align-middle",
      cellTemplate: "requestMetadataTemplate",
      dataField: "requestDate",
      dataType: "date",
      filterOperations: ['=', '<', '>='],
      calculateFilterExpression: function (filterValue) {
        return calculateFilterForDates(filterValue, "requestedOn");
      }
    },
    {
      cellTemplate: "commandTemplate",
      allowSorting: false,
      cssClass: "align-middle",
      width: "52",
      allowResizing: false,
    },

  ];

  // I could not figure out which object e ist, documentation:
  // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
  onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.dataField === "status") {
      e.cellElement.classList.add("matchingStatus" + e.value);
    }
  }

  error: any = null;
  sortKey = sortOptions;
  matchings: DataSource = matchingSource;
  notFoundText: string = "Es liegen keine Matchings für den gewählten Filter vor.";
  remoteMatchingOperations: RemoteOperations = remoteOperations;
  renderToggle: boolean = false;

  // FIXME: use something similar to a semaphore
  processingButtonClick: boolean = false;

  proceedButtonClick(matching: Matching): void {
    if (!this.processingButtonClick) {
      this.processingButtonClick = true;
      this.$store.commit("selectMatching", matching);


      if (matching.isSupplier && matching.status === StatusEnum.new) {
        this.$router.push({
          name: AGREEMENT_SELECTION_ROUTE_NAME,
          params: {
            id: matching.id,
            toolName: STATE_AGREEMENT_SELECTION,
          },
        });
      } else {
        this.$router.push({
          name: AGREEMENT_DETAILS_ROUTE_NAME,
          params: {
            id: matching.id,
            toolName: STATE_AGREEMENT_DETAILS,
          },
        });
      }
      this.processingButtonClick = false;
    }
  }

  beforeCreate(): void {
      if(this.$route.params.customer) {
          this.oAuthUserForSyncFilterValue = this.$route.params.customer;
      }
      if(this.$route.params.status){
          this.statusFilterValue = parseInt(this.$route.params.status);
      }
      if(this.$route.params.isSupplier){
          this.isSupplierFilterValue = this.$route.params.isSupplier;
      }
  }

  mounted(): void {
    this.$store.commit("clearMatching");
    this.$store.commit("clearAgreement");
    this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
    this.$store.commit("updateViewLabel", MatchingList.VIEW_LABEL);
    EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.createMatchingCommandButton);
  }

  beforeDestroy(): void {
      EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
      this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.createMatchingCommandButton);
  }

  handleRefresh(): void {
    this.matchings.reload();
    this.refreshView();
  }

  abortButtonClick(matching: Matching): void {
    if (!this.processingButtonClick) {
      this.processingButtonClick = true;
      MatchingConnector.cancelMatching(matching)
          .then(() => this.matchings.reload())
          .catch(
              error => {
                defaultFetchErrorHandler(error);
                this.error = error;
              }
          )
          .finally(() => this.processingButtonClick = false);
    }
  }

  proceedToCreation(): void {
    this.$router.push({name: 'matchableAgreementSelection'});
  }

  refreshView(): void {
    this.renderToggle = !this.renderToggle;
  }

  _expandRow(e: any): void {
     e.component.collapseAll(-1);
      if (!e.isExpanded) {
        e.component.expandRow(e.key);
      }
  }

}

