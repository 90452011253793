











import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
    },
    props: {
        primary: {
            type: String,
        },
        secondary: {
            type: String,
        },
        wrapContent: {
            type: Boolean,
            default: false,
        }
    }
})
export default class PrimarySecondaryElement extends Vue {

    dynamicClasses(wrapContent: boolean): string {
        if (wrapContent) {
            return "multilineCell"
        } else {
            return "ellipsisCell"
        }
    }

}
