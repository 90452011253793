















import {Component, Vue} from "vue-property-decorator";
import {Matching} from "@/types/Matching";
import {AGREEMENT_DETAILS_ROUTE_NAME, AGREEMENT_SELECTION_ROUTE_NAME} from "@/router";
import {FLUENT_ICON_ZOOM, STATE_AGREEMENT_DETAILS, STATE_AGREEMENT_SELECTION} from "@/types/StateConst";
import {getDateString} from "@/util/string.module";


@Component({
    components: {},
    props: {
        matching: {
            type: Matching,
            required: true,
        },
    },
    methods: {
        getDateString: getDateString,
    },
})
export default class MatchingCard extends Vue {

    readonly zoomIcon = FLUENT_ICON_ZOOM;

    routToMatchingDetailView(matching: Matching): void {
        this.$store.commit("selectMatching", matching);

        if(matching.status === 1) {
            this.$router.push({
                name: AGREEMENT_SELECTION_ROUTE_NAME,
                params: {
                    id: matching.id,
                    toolName: STATE_AGREEMENT_SELECTION,
                },
            });
        } else {
            this.$router.push({
                name: AGREEMENT_DETAILS_ROUTE_NAME,
                params: {
                    id: matching.id,
                    toolName: STATE_AGREEMENT_DETAILS,
                },
            });
        }
    }
}

