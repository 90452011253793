




























import {Component, Vue} from "vue-property-decorator";
import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxTooltip
} from 'devextreme-vue/chart';
import {retrieveWeeklyStats} from "@/util/insights.module";
import {defaultFetchErrorHandler} from "@/main";
import {WeeklyStackBarStats} from "@/types/WeeklyStackBarStats";

export class WeekItem {
    [key: string]: number | string

    week: string = "";
    day1: number = 0;
    day2: number = 0;
    day3: number = 0;
    day4: number = 0;
    day5: number = 0;
    day6: number = 0;
    day7: number = 0;
}

type weekSourceMapKey = "value" | "name";
type weekSourceMap = Record<weekSourceMapKey, string>;

@Component({
    components: {
        DxChart,
        DxSeries,
        DxCommonSeriesSettings,
        DxValueAxis,
        DxTitle,
        DxTooltip
    },
})
export default class WeeklyChartWidget extends Vue {
    firstWeek: string = "";
    weekItem: WeekItem = new WeekItem();

    dataSource: WeekItem[] = [];

    //Due to the Java Datetime Api, the index of a 'dayOfWeek' starts at 1.
    weekSources: weekSourceMap[] = [
        {value: "day1", name: "Montag"},
        {value: "day2", name: "Dienstag"},
        {value: "day3", name: "Mittwoch"},
        {value: "day4", name: "Donnerstag"},
        {value: "day5", name: "Freitag"},
        {value: "day6", name: "Samstag"},
        {value: "day7", name: "Sonntag"}
    ];

    mounted() {

        retrieveWeeklyStats()
            .then(stats => {
                for (const stat of stats) {
                    this.handleWeekItems(stat);
                }
                this.dataSource.push(this.weekItem);
            }).catch(defaultFetchErrorHandler);
    }

    private setDayOfWeek(stat: WeeklyStackBarStats): void {
        for (let i = 1; i <= 7; i++) {
            if (stat.dayOfWeek === i) {
                this.weekItem["day" + i] = stat.total;
            }
        }
    }

    private handleWeekItems(stat: WeeklyStackBarStats): void {
        if (this.firstWeek === "") {
            this.firstWeek = stat.week;
            this.weekItem.week = stat.week + ". KW";
        }

        if (stat.week === this.firstWeek) {
            this.setDayOfWeek(stat);

        } else {
            this.dataSource.push(this.weekItem);
            this.firstWeek = stat.week;

            this.weekItem = new WeekItem();
            this.weekItem.week = stat.week + ". KW";
            this.setDayOfWeek(stat);
        }
    }

    customizeTooltip(arg: any) {
        let ordersText: string = ' Aufträge';
        if (arg.valueText === 1 || arg.valueText === "1") {
            ordersText = ' Auftrag'
        }
        return {
            text: arg.valueText + ordersText
        };
    }
}

