import {DataSourceOptions} from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {Partner} from "@/types/Partner";
import {LoadOptions} from "devextreme/data/load_options";
import {ResultPage} from "@gipa/devextreme-to-spring";
import PartnerConnector from "@/components/partner/PartnerConnector";


export const PartnerLookupDSO: DataSourceOptions = {
    store: new CustomStore({
        key: "gipaOAuthUser",
        byKey(key: string): Promise<Partner> {
            return PartnerConnector.fetchPartnerByOAuthUserForSync(key);
        },
        load(loadOptions: LoadOptions): Promise<ResultPage<Partner>> {
            return PartnerConnector.getInstance().filter(loadOptions);
        }
    }),
    paginate: true,
    pageSize: 10,
}