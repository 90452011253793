
export {AdditionalContent}

class AdditionalContent {
    readonly type: string;
    readonly content: string;

    constructor(type: string, content: string) {
        this.type = type;
        this.content = content;
    }

    equals(other: AdditionalContent): boolean {
        return (this.type === other.type && this.content === other.content);
    }

}
