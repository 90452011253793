import {MutationTree} from "vuex";
import {AvalState, ViewLabel} from "@/store/types";
import {Matching} from "@/types/Matching";
import {Partner} from "@/types/Partner";
import {Agreement} from "@/types/Agreement";
import {BackendProperties} from "@/types/BackendProperties";
import {
    ADD_COMMAND_BAR_ELEMENT,
    AUTHENTICATED,
    CHANGE_BREADCRUMB,
    DISABLE_CANDIS_EMBEDDED_MODE,
    ENABLE_CANDIS_EMBEDDED_MODE,
    LOGGED_OUT,
    REMOVE_COMMAND_BAR_ELEMENT,
    SWITCH_TO_TENANT,
} from "@/types/MutationConst";
import {Tenant, TenantContext, TenantRole} from "@/util/TenantContext";
import {UFSelectBoxOptions} from "gipa-universal-frame/src/types/UFSelectBoxOptions";
import { StaticItem } from 'gipa-universal-frame/src/types/StaticItem';
import {defaultFetchErrorHandler, EventBus, store} from "@/main";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import { InteractionElement } from 'gipa-universal-frame/src/types/UniversalFrameState';
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {
    DOCUMENT_TYPE_MAPPING_BUTTON,
    HOME_BUTTON,
    MATCHING_LIST_BUTTON,
    PARTNER_LIST_BUTTON,
    TRANSACTION_LIST_BUTTON
} from "@/store/NavigationButtons";
import {getBackendProperties} from "@/util/BackendPropertiesConnector";

function _getNavigationButtons(adminViewsVisible: boolean): UFButtonOptions[] {
    const result: UFButtonOptions[] = [
        HOME_BUTTON,
        MATCHING_LIST_BUTTON,
        TRANSACTION_LIST_BUTTON,
    ];
    if (adminViewsVisible) {
        result.push(PARTNER_LIST_BUTTON);
        result.push(DOCUMENT_TYPE_MAPPING_BUTTON);
    }
    return result;
}

export function navigationErrorHandler(error: any): void {
    if (error.toString().startsWith("NavigationDuplicated")) {
        // this is normal behaviour if the navigation button to a view is used while that view is already loaded
        // https://github.com/vuejs/vue-router/issues/2872
        return;
    }
    console.log(error);
}

export const mutations: MutationTree<AvalState> = {

    selectMatching(state: AvalState, selected: Matching): void {
        state.matching = selected;
    },

    clearMatching(state: AvalState): void {
        state.matching = null;
        state.matchingContextVisible = false;
    },

    clearPartner(state: AvalState): void {
        state.partner = null;
    },

    clearAgreement(state: AvalState): void {
        state.agreement = null;
        state.agreementContextVisible = false;
    },

    [CHANGE_BREADCRUMB](state: AvalState, payload: BreadcrumbItem[]): void {
        state.tool.breadcrumb.changeBreadcrumb(payload);
    },

    changeToolName(state: AvalState, payload: string): void {
        state.tool.toolSubToolName = payload;
    },

    updateViewLabel(state: AvalState, viewLabel: ViewLabel): void {
        state.viewLabel = viewLabel;
    },

    setMatchingContextVisibility(state: AvalState, visible: boolean): void {
        state.matchingContextVisible = visible;
    },

    setAgreementContextVisibility(state: AvalState, visible: boolean): void {
        state.agreementContextVisible = visible;
    },

    [AUTHENTICATED](state: AvalState, jwt: string): void {
        state.auth.jwt = jwt;
        console.debug("'auth' state mutated.");
        // update navigationButtons
        const buttons: UFButtonOptions[] = _getNavigationButtons(state.auth.adminViewsVisible);
        buttons.forEach(button => state.navigationButtons.push(button));
        // update TenantContext
        state.tenantContext.allowedTenants = state.auth.roles.filter(r => r.startsWith("ROLE_TENANT"))

        // request (& later update) BackendProperties
        getBackendProperties().then(
            response => {
                store.commit("backendPropertyStates", response);
            }, error => {
                defaultFetchErrorHandler(error);
            });

    },

    [LOGGED_OUT](state: AvalState): void {
        state.auth.jwt = "";
        state.tool.breadcrumb.clear();
        state.tenantSelectBox = null;
        state.navigationButtons.splice(0, state.navigationButtons.length);
    },

    updateAuthenticationDisplayMessage(state: AvalState, msg: string): void {
        state.auth.errorDisplayMsg = msg;
    },

    selectPartner(state: AvalState, selected: Partner): void {
        state.partner = selected;
    },

    selectAgreement(state: AvalState, selected: Agreement): void {
        state.agreement = selected;
    },

    backendPropertyStates(state: AvalState, properties: BackendProperties): void {
        state.backendVersion = properties.backendVersion;
        state.tenantContext.existingTenants = properties.existingTenants;
        // FIXME - do not call store.commit in a store.commit operation
        store.commit("iniTenantSelectBox");
    },

    iniTenantSelectBox(state: AvalState) {
        const tenantContext: TenantContext = state.tenantContext;

        const items: StaticItem[] = tenantContext.accessibleTenants.map((tenant: Tenant) => {
            return {label: tenant.name, callbackParameter: tenant.role};
        });

        const selectedItem: StaticItem = {label: tenantContext.activeTenantName, callbackParameter: tenantContext.activeTenantRole};

        state.tenantSelectBox = new UFSelectBoxOptions(
            items,
            (tenantRole) => {
                store.commit(SWITCH_TO_TENANT, tenantRole);
                EventBus.$emit(COMMAND_BAR_REFRESH_EVENT);
            },
            "Mandant wählen",
            selectedItem
        );
    },

    [SWITCH_TO_TENANT](state: AvalState, tenantRole: TenantRole): void {
        state.tenantContext.desiredTenant = tenantRole;
    },

    [ADD_COMMAND_BAR_ELEMENT](state: AvalState, element: InteractionElement) {
        state.commandBarElements.push(element);
    },

    [REMOVE_COMMAND_BAR_ELEMENT](state: AvalState, element: InteractionElement) {
        const elementIndex: number = state.commandBarElements.indexOf(element);
        state.commandBarElements.splice(elementIndex, 1);
    },

    [ENABLE_CANDIS_EMBEDDED_MODE](state: AvalState) {
        state.showNavigationBar = false;
        state.showToolHeaderBar = false;
        state.showBaseHeader = false;
    },

    [DISABLE_CANDIS_EMBEDDED_MODE](state: AvalState) {
        state.showNavigationBar = true;
        state.showToolHeaderBar = true;
        state.showBaseHeader = true;
    },

};
