

























































import {Component, Vue} from "vue-property-decorator";
import {retrieveMatchingStats, StatisticTuple} from "@/util/insights.module";
import {defaultFetchErrorHandler} from "@/main";
import {StatusEnum} from "@/types/Matching";
import {DxButton} from "devextreme-vue";
import {MATCHING_LIST_ROUTE_NAME} from "@/router";

@Component({
    components: {
        DxButton
    },
})
export default class StatusWidget extends Vue {

    newStatisticAsSupplier: StatisticTuple = new StatisticTuple();
    matchedStatisticAsSupplier: StatisticTuple = new StatisticTuple();
    newStatisticAsClient: StatisticTuple = new StatisticTuple();
    matchedStatisticAsClient: StatisticTuple = new StatisticTuple();
    cancelledStatistic: StatisticTuple = new StatisticTuple();

    data() {
        return {
            StatusEnum: StatusEnum
        }
    }

    mounted() {
        this.loadStatistic();
    }

    loadStatistic(): void {
        retrieveMatchingStats()
            .then((stats: Map<string, StatisticTuple>) => {
                this.handleStatisticLoadResult(stats);
            })
            .catch(defaultFetchErrorHandler);

    }

    handleStatisticLoadResult(stats: Map<string, StatisticTuple>): void {
        let value: StatisticTuple | undefined;

        // I have not found a clean way to access values of a Map without getting compiler warnings about mismatched type (undefined)
        value = stats.get(StatusEnum.new.toString() + true);
        if (value) {
            this.newStatisticAsSupplier = value;
        }
        value = stats.get(StatusEnum.new.toString() + false);
        if (value) {
            this.newStatisticAsClient = value;
        }
        value = stats.get(StatusEnum.matched.toString() + true);
        if (value) {
            this.matchedStatisticAsSupplier = value;
        }
        value = stats.get(StatusEnum.matched.toString() + false);

        if (value) {
            this.matchedStatisticAsClient = value;
        }
        value = stats.get(StatusEnum.cancelled.toString() + true);
        if (value) {
            this.cancelledStatistic.count += value.count;
            this.cancelledStatistic.classifier = value.classifier;
        }
        value = stats.get(StatusEnum.cancelled.toString() + false);
        if (value) {
            this.cancelledStatistic.count += value.count;
            if (Date.parse(value.classifier) > Date.parse(this.cancelledStatistic.classifier)) {
                this.cancelledStatistic.classifier = value.classifier;
            }
        }

    }

    routeToFilteredMatchings(status: StatusEnum, role: boolean): void {
        this.$router.push({
            name: MATCHING_LIST_ROUTE_NAME,
            params: {status: status + "", isSupplier: role + ""},
        })
    }

    routeToCancelledMatchings(): void {
        this.$router.push({
            name: MATCHING_LIST_ROUTE_NAME,
            params: {status: StatusEnum.cancelled + ""},
        })
    }

}
