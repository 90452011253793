

















import {Component, Vue} from "vue-property-decorator";
import {retrieveMostMatchTransactionStat} from "@/util/insights.module";
import {defaultFetchErrorHandler} from "@/main";
import {DxButton} from "devextreme-vue";
import {TRANSACTION_LIST_ROUTE_NAME} from "@/router";

@Component({
    components: {
        DxButton
    },
})

export default class MatchPositionWidget extends Vue {

    mostRequester: string = "";
    requestTotal: string = "";

    routeToFilteredTransactions(requestPerson: string): void {
        this.$router.push({name: TRANSACTION_LIST_ROUTE_NAME});
    }

    mounted() {
        retrieveMostMatchTransactionStat().then(stat => {
            this.mostRequester = stat.name;

            if (stat.total > 0) {
                if (stat.total == 1) {
                    this.requestTotal = stat.total.toString() + " Auftrag";
                }
                if (stat.total > 1) {
                    this.requestTotal = stat.total.toString() + " Aufträge";
                }
            }
        }).catch(defaultFetchErrorHandler);
    }

}
