import {TransactionJSON} from "@/types/Transaction/Transaction";

export {ServiceResponse, ServiceResponseDiff};

class ServiceResponse {
    readonly isUnderMeasureThreshold: boolean;
    readonly serviceNote: string;
    readonly governmentalAsset: string;
    readonly governmentalCarrier: string;
    readonly complaintReason: number;

    constructor(json: TransactionJSON) {
        this.isUnderMeasureThreshold = json.isUnderMeasureThreshold;
        this.serviceNote = json.serviceNoteNumber;
        this.governmentalAsset = json.governmentalAssetNumber;
        this.governmentalCarrier = json.governmentalCarrierNumber;
        this.complaintReason = json.complaintReason;
    }

}

class ServiceResponseDiff {
    readonly isUnderMeasureThreshold: boolean | null;
    readonly serviceNote: string | null;
    readonly governmentalAsset: string | null;
    readonly governmentalCarrier: string | null;
    readonly complaintReason: number | null;

    constructor(sr1: ServiceResponse, sr2: ServiceResponse | null) {
        let isUnderMeasureThreshold: boolean | null = sr1.isUnderMeasureThreshold;
        let serviceNote: string | null = sr1.serviceNote;
        let governmentalAsset: string | null = sr1.governmentalAsset;
        let governmentalCarrier: string | null = sr1.governmentalCarrier;
        let complaintReason: number | null = sr1.complaintReason;
        if (sr2) {
            if (sr2.isUnderMeasureThreshold === isUnderMeasureThreshold) {
                isUnderMeasureThreshold = null;
            }
            if (sr2.serviceNote === serviceNote) {
                serviceNote = null;
            }
            if (sr2.governmentalAsset === governmentalAsset) {
                governmentalAsset = null;
            }
            if (sr2.governmentalCarrier === governmentalCarrier) {
                governmentalCarrier = null;
            }
            if (sr2.complaintReason === complaintReason) {
                complaintReason = null;
            }
        }
        this.isUnderMeasureThreshold = isUnderMeasureThreshold;
        this.serviceNote = serviceNote;
        this.governmentalAsset = governmentalAsset;
        this.governmentalCarrier = governmentalCarrier;
        this.complaintReason = complaintReason;
    }

}
