

































































































































































































import {Component, Vue} from "vue-property-decorator";
import {ViewLabel} from "@/store/types";
import {
    STATE_BASIC_AUTHENTICATION, GRANT_TYPE_CLIENT_CREDENTIALS, GRANT_TYPE_PASSWORD,
    STATE_OAUTH2_AUTHENTICATION,
    STATE_PARTNER_DETAILS, FLUENT_ICON_PARTNERS, FLUENT_ICON_SAVE, FLUENT_ICON_DELETE
} from "@/types/StateConst";
import DxButton from "devextreme-vue/button";
import DxSelectBox from "devextreme-vue/select-box";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import {Partner} from "@/types/Partner";
import {State} from "vuex-class";
import DxTextBox, {DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';
import PartnerConnector from "@/components/partner/PartnerConnector";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import DxLoadPanel from "devextreme-vue/load-panel";
import {OAuth2Authentication} from "@/types/OAuth2Authentication";
import {BasicAuthentication} from "@/types/BasicAuthentication";
import notify from "devextreme/ui/notify";
import {alert, confirm} from "devextreme/ui/dialog";
import {DxRequiredRule, DxValidator} from "devextreme-vue/validator";
import {Authentication} from "gipa-universal-frame/src/types/Authentication";
import {DxValidationSummary} from "devextreme-vue";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {PARTNER_DETAILS_ROUTE_NAME, PARTNER_LIST_ROUTE_NAME} from "@/router";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";

const PASSWORD_MODE_HIDDEN = "password";
const PASSWORD_MODE_VISIBLE = "text";

@Component({
    components: {
        DxButton,
        DxSelectBox,
        DxDropDownBox,
        DxTextBox,
        DxLoadPanel,
        DxRequiredRule,
        DxValidator,
        DxTextBoxButton,
        DxValidationSummary,
    },
})
export default class PartnerDetails extends Vue {
    @State(state => state.partner) statePartner: Partner | null | undefined;
    @State(state => state.auth) auth: Authentication | null | undefined;

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_PARTNER_DETAILS,
        false,
        { name: PARTNER_DETAILS_ROUTE_NAME }
    );

    basicPasswordMode: string = PASSWORD_MODE_HIDDEN;
    oAuthPasswordMode: string = PASSWORD_MODE_HIDDEN;
    clientSecretPasswordMode: string = PASSWORD_MODE_HIDDEN;

    basicPasswordButton: any = {
        icon: "img/icons/password_visible.png",
        type: "default",
        onClick: () => {
            this.setPasswordVisibleForBasic()
        }
    };
    oAuthPasswordButton: any = {
        icon: "img/icons/password_visible.png",
        type: "default",
        onClick: () => {
            this.setPasswordVisibleForOAuth()
        }
    };
    clientSecretButton: any = {
        icon: "img/icons/password_visible.png",
        type: "default",
        onClick: () => {
            this.setPasswordVisibleForClientSecret()
        }
    };

    preselectedAuthentication: string = "";

    error: any = null;

    readonly supportedAuthenticationMethods: string[] = [STATE_BASIC_AUTHENTICATION, STATE_OAUTH2_AUTHENTICATION];
    readonly supportedGrantTypes: string[] = [GRANT_TYPE_PASSWORD, GRANT_TYPE_CLIENT_CREDENTIALS];
    readonly supportedStates: string[] = ["INACTIVE", "ACTIVE"];
    readonly supportedVersions: string[] = ["v0.5", "v1.0", "v1.5", "v2.0"];

    readonly iconSave: string = FLUENT_ICON_SAVE;

    public static readonly BASIC_VIEW_LABEL: ViewLabel = {
        primary: "Partner Details",
        secondary: false,
        icon: FLUENT_ICON_PARTNERS,
        cssClass: "Partner",
    };

    private readonly deletePartnerButton: UFButtonOptions = new UFButtonOptions(
        () => {
            this.deletePartner();
        },
        "Partner löschen",
        FLUENT_ICON_DELETE,
        "Partner löschen"
    );

    mounted(): void {

        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
        this.$store.commit("updateViewLabel", PartnerDetails.BASIC_VIEW_LABEL);

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.deletePartnerButton);

        if (!this.statePartner) {
            this.loadPartner(this.$route.params.id);
        } else {
            this.handlePartnerIsLoaded();
        }

    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.deletePartnerButton);
        this.$store.commit("clearPartner");
    }

    handleRefresh(): void {
        this.$store.commit("clearPartner");
        this.loadPartner(this.$route.params.id);
    }

    loadPartner(id: string): void {
        PartnerConnector.getInstance().loadById(id).then(
            partner => {
                this.$store.commit("selectPartner", partner);
                this.handlePartnerIsLoaded();
            }, error => {
                this.$store.commit("updateViewLabel", PartnerDetails.BASIC_VIEW_LABEL);
                defaultFetchErrorHandler(error);
                this.error = error;
            }
        );
    }

    handlePartnerIsLoaded(): void {
        if(this.statePartner) {
            if (this.statePartner.basicAuth) {
                this.preselectedAuthentication = "BasicAuthentication";
            } else if (this.statePartner.oAuth) {
                this.preselectedAuthentication = "OAuth2Authentication";
            }
            if (this.statePartner.partnerId) {
                const viewLabel: ViewLabel = {
                    primary: PartnerDetails.BASIC_VIEW_LABEL.primary,
                    icon: PartnerDetails.BASIC_VIEW_LABEL.icon,
                    cssClass: this.getCssClass(),
                    secondary: this.statePartner.partnerId
                };
                this.$store.commit("updateViewLabel", viewLabel);
            }
            this.addDefaultsToPartner();
        }
    }

    addDefaultsToPartner(): void {
        if(!this.statePartner) {
            return;
        }

        if (this.statePartner.state == null || this.statePartner.state == "") {
            this.statePartner.state = this.supportedStates[0];
        }
        if (this.statePartner.version == null || this.statePartner.version == "") {
            this.statePartner.version = this.supportedVersions[1];
        }
        if (this.statePartner.basicAuth == null && this.statePartner.oAuth == null) {
            this.statePartner.oAuth = new OAuth2Authentication("", "", "", "", GRANT_TYPE_PASSWORD, "", "");
            this.preselectedAuthentication = "OAuth2Authentication";
        }
    }

    savePartner(e: any): void {
        const result = e.validationGroup.validate();
        if (result.isValid && this.statePartner) {
            this.patchPartner(this.statePartner);
        }
    }

    patchPartner(partner: Partner) {
        PartnerConnector.getInstance().update(partner, partner.self).then(
            response => {
                this.handlePatchPartner(response);
                notify("Das Speichern des Partners " + partner.name + " war erfolgreich");
            },
            error => {
                defaultFetchErrorHandler(error);
                notify({ message: "Der Partner konnte nicht gespeichert werden.", shading: true }, "error", 2718);
            }
        )
    }

    deletePartner() {
        if (!this.statePartner) {
            // this might happen if partner gets loaded and Button is clicked, we can ignore such button clicks
            return;
        }
        const partner: Partner = this.statePartner;
        const message: string = "Wollen Sie den aktuellen Partner (" + partner.name + ") löschen?";
        confirm(message, "Partner löschen").then((dialogResult) => {
            if (dialogResult) {
                PartnerConnector.getInstance().delete(partner.self).then(
                    response => {
                        this.$router.push({name: PARTNER_LIST_ROUTE_NAME});
                        notify("Der Partner wurde erfolgreich gelöscht", "Success");
                    },
                    error => {
                        defaultFetchErrorHandler(error);
                        this.error = error;
                        alert("Der Partner kann nicht gelöscht werden, da er Matchings hat!", "Partner Löschen");
                    });
            }
        });
    }


    handlePatchPartner(updatedPartner: Partner): void {
        if (updatedPartner.partnerId != null) {
            this.$store.commit("selectPartner", updatedPartner);
            this.$router.push({name: 'partnerDetails', params: {id: updatedPartner.partnerId,},});
        }
    }

    changeAuthenticationView(e: any): void {
        if (this.statePartner) {
            if (e.value === STATE_OAUTH2_AUTHENTICATION && this.statePartner.oAuth === null) {
                this.statePartner.basicAuth = null;
                this.statePartner.oAuth = new OAuth2Authentication("", "", "", "", GRANT_TYPE_PASSWORD, "", "");
            } else if (e.value === STATE_BASIC_AUTHENTICATION && this.statePartner.basicAuth === null) {
                this.statePartner.oAuth = null;
                this.statePartner.basicAuth = new BasicAuthentication("", "");
            }
        }
    }

    changeAuthenticationFieldsForGrantType(e: any): void {
        if (this.statePartner && this.statePartner.oAuth) {
            if (e.value === GRANT_TYPE_CLIENT_CREDENTIALS) {
                this.statePartner.oAuth.basicAuthUsername = "";
                this.statePartner.oAuth.basicAuthPassword = "";
            }
        }
    }

  /**
   * Sets the corresponding field to null, if the value = "" and it is therefore empty
   * Checks if the field parameter exists on the oAuth object, before nulling it
   * null as any is a workaround, to prevent not assignable error on the field
   * @param e event
   * @param field corresponding field
   */
  setEmptyFieldToDefaultNull(e: any, field: string): void {
    if (this.statePartner && this.statePartner.oAuth && e.value == "" && Object.keys(this.statePartner.oAuth).includes(field)) {
      this.statePartner.oAuth[field as keyof OAuth2Authentication] = null as any;
    }
  }

    getCssClass(): string {
        if (this.statePartner) {
            return this.statePartner?.getBackgroundStatusClass();
        }
        return "";
    }

    setPasswordVisibleForBasic(): void {
        if (this.basicPasswordMode === PASSWORD_MODE_HIDDEN) {
            this.basicPasswordMode = PASSWORD_MODE_VISIBLE;
        } else {
            this.basicPasswordMode = PASSWORD_MODE_HIDDEN;
        }
    }

    setPasswordVisibleForOAuth(): void {
        if (this.oAuthPasswordMode === PASSWORD_MODE_HIDDEN) {
            this.oAuthPasswordMode = PASSWORD_MODE_VISIBLE;
        } else {
            this.oAuthPasswordMode = PASSWORD_MODE_HIDDEN;
        }
    }

    setPasswordVisibleForClientSecret(): void {
        if (this.clientSecretPasswordMode === PASSWORD_MODE_HIDDEN) {
            this.clientSecretPasswordMode = PASSWORD_MODE_VISIBLE;
        } else {
            this.clientSecretPasswordMode = PASSWORD_MODE_HIDDEN;
        }
    }

    showSlot(): boolean {
        return (this.statePartner?.oAuth?.grantType === 'password')
    }

}

