































































































import {Component, Vue} from "vue-property-decorator";
import {Matching, StatusEnum} from "@/types/Matching";
import DxDataGrid from 'devextreme-vue/data-grid';
import {Agreement} from "@/types/Agreement";
import DxTooltip from "devextreme-vue/tooltip";
import {ViewLabel} from "@/store/types";
import {
    FLUENT_ICON_AGREEMENT_SELECTION,
    FLUENT_ICON_LINK, FLUENT_ICON_REJECT,
    STATE_AGREEMENT_SELECTION,
} from "@/types/StateConst";
import MatchingConnector from "@/components/matching/MatchingConnector";
import MatchingStatusBar from "@/components/matching/MatchingStatusBar.vue";
import DxCheckBox from "devextreme-vue/check-box";
import agreementDataSource, {remoteOperations} from "@/components/matching/AgreementDatasource";
import {RemoteOperations} from "@/types/RemoteOperations";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getInitials} from '@/util/string.module';
import DxButton from "devextreme-vue/button";
import DataSource = DevExpress.data.DataSource;
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;
import dxDataGrid from "devextreme/ui/data_grid";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {AGREEMENT_SELECTION_ROUTE_NAME} from "@/router";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {DxLoadPanel} from "devextreme-vue";

@Component({
    components: {
        MatchingStatusBar,
        DxDataGrid,
        DxTooltip,
        DxCheckBox,
        DxLoadPanel,
        StatusIcon,
        DxButton,
    },
    methods: {
        getInitials: getInitials,
    },
})
export default class AgreementSelection extends Vue {

    static LOCATION_CAPTION: string = 'Anfallstelle';

    agreementGrid?: dxDataGrid;

    saveGridInstance(e: any): void {
        this.agreementGrid = e.component;
    }

    public static readonly BASE_VIEW_LABEL: Readonly<ViewLabel> = {
        primary: "Anfrage Detail",
        secondary: false,
        icon: FLUENT_ICON_AGREEMENT_SELECTION,
        cssClass: "AgreementSelection",
    };

    private readonly cancelMatchingCommandButton: UFButtonOptions = new UFButtonOptions(
        () => { this.abortButtonClick(); },
        "Matchinganfrage ablehnen",
        FLUENT_ICON_REJECT,
        "Matchinganfrage erstellen"
    );

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_AGREEMENT_SELECTION,
        false,
        { name: AGREEMENT_SELECTION_ROUTE_NAME },
        false
    );

    data(): any {
        return {AgreementSelection};
    }

    readonly linkIcon: string = FLUENT_ICON_LINK;

    readonly loadingText: string = "Lade ...";
    readonly processingMessage: string = "Verarbeite Verküpfung ...";

    preFilter: boolean = true;

    readonly notFoundText: string = "Es konnten keine passenden CANDIS Vereinbarung für diese Matching Anfrage ermittelt werden. \n\nBitte entfernen Sie den " +
        "Filter und verwenden die Liste aller verfügbaren Vereinbarungen.";
    processing: boolean = false;
    error: any = null;
    selectedMatching: Matching | null = null;

    remoteAgreementOperations: RemoteOperations = remoteOperations;
    agreements: DataSource = agreementDataSource;

    // FIXME: use something similar to a semaphore
    processingButtonClick: boolean = false;

    dxAgreementColumns: dxDataGridColumn[] = [
        {
            caption: '',
            allowSorting: false,
            allowFiltering: false,
            cssClass: "align-middle",
            cellTemplate: "statusIconTemplate",
            dataType: "object",
            width: "52",
            allowResizing: false,
        },
        {
            caption: 'Kunde',
            dataField: 'customer',
            allowSorting: true,
            cssClass: "align-middle multiColumnCell",
            dataType: "string",
            filterOperations: ['contains'],
        },
        {
            caption: AgreementSelection.LOCATION_CAPTION,
            dataField: 'location',
            cssClass: "align-middle multiColumnCell",
            dataType: "string",
            filterOperations: ['contains'],
        },
        {
            caption: 'Bezeichnung',
            dataField: 'title',
            cssClass: "align-middle multiColumnCell",
            filterOperations: ['contains'],
            dataType: "string",
        },
        {
            caption: 'Beschreibung',
            dataField: 'description',
            allowSorting: true,
            cssClass: "align-middle multiColumnCell",
            filterOperations: ['contains'],
            dataType: "string",
        },
        {
            cellTemplate: "commandTemplate",
            allowSorting: false,
            allowFiltering: false,
            dataType: "object",
            width: "52",
            allowResizing: false,
        },
    ];

    renderToggle: boolean = false;

    mounted(): void {
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);

        const viewLabel: ViewLabel = AgreementSelection.BASE_VIEW_LABEL;
        this.$store.commit("updateViewLabel", viewLabel);

        if (this.$store.state.matching === null) {
            this.loadMatching(this.$route.params.id);
        } else {
            this.handleMatchingIsLoaded();
        }

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.cancelMatchingCommandButton);
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.cancelMatchingCommandButton);
    }

    handleRefresh(): void {
        this.loadMatching(this.$route.params.id);
    }

    linkClick(agreement: Agreement): void {
        if (!this.processingButtonClick) {
            this.processingButtonClick = true;

            this.processing = true;
            if (!this.selectedMatching) {
                alert("Fehler!\nEs ist kein Matching ausgewählt worden!");
                this.$router.push({name: 'matchingList'});
            } else {
                MatchingConnector.matchAgreement(this.selectedMatching, agreement.self).then(
                    response => {
                        this.handleMatchedResponse(response);
                    },
                    error => {
                        defaultFetchErrorHandler(error);
                        this.error = error;
                    }
                ).finally(
                    () => {
                        this.handleProcessingButtonClickFinished();
                    }
                )
            }
        }
    }

    handleProcessingButtonClickFinished(): void {
        this.processingButtonClick = false;
    }

    handleMatchedResponse(updatedMatching: Matching): void {
        this.$store.commit("selectMatching", updatedMatching);
        this.$router.push({
            name: 'agreementDetails',
            params: {
                id: updatedMatching.id,
            },
        });
    }

    loadMatching(id: string): void {
        MatchingConnector.getInstance().loadById(id).then(
            matching => {
                this.$store.commit("selectMatching", matching);
                this.handleMatchingIsLoaded();
            }, error => {
                defaultFetchErrorHandler(error);
                this.error = error;
            }
        );
    }

    handleMatchingIsLoaded(): void {
        this.selectedMatching = this.$store.state.matching as Matching;
        this.$store.commit("setMatchingContextVisibility", true);

        if (!this.selectedMatching.isSupplier || this.selectedMatching.status === StatusEnum.matched
            || this.selectedMatching.status === StatusEnum.cancelled) {
            this.$router.push({
                name: 'agreementDetails',
                params: {
                    id: this.selectedMatching.id,
                },
            });
        }

        const viewLabel: ViewLabel = {
            primary: AgreementSelection.BASE_VIEW_LABEL.primary + " (AN)",
            icon: AgreementSelection.BASE_VIEW_LABEL.icon,
            cssClass: this.selectedMatching.getStatusClass() + "Agreement",
            secondary: this.selectedMatching.id
        };
        this.$store.commit("updateViewLabel", viewLabel);

        this.filterAgreements();
    }

    extractStreetAndZip(location: string): string {
        if (location === "") return "";
        let street: string = "";
        let zip: string = "";
        const streetMatch: RegExpMatchArray | null = location.match(/^[^\s]*/);
        const zipMatch:  RegExpMatchArray | null = location.match(/\s\d\d\d\d\d\s\w*/);
        if (streetMatch) {
            street = streetMatch.toString();
        }
        if (zipMatch) {
            zip = zipMatch.toString();
        }
        return street + zip;
    }

    filterAgreements(): void {
        if (!this.agreementGrid) {
            // initialization of this view is not done yet.
            return;
        }
        if (this.preFilter && this.selectedMatching) {
            this.agreementGrid.columnOption(
                AgreementSelection.LOCATION_CAPTION,
                "filterValue",
                this.extractStreetAndZip(this.selectedMatching.client.performancePlace)
            )
        } else {
            this.agreementGrid.columnOption(
                AgreementSelection.LOCATION_CAPTION,
                "filterValue",
                undefined
            )
        }
        agreementDataSource.reload();
    }

    _expandRow(e: any): void {
        e.component.collapseAll(-1);
        if (!e.isExpanded) {
            e.component.expandRow(e.key);
        }
    }

    abortButtonClick(): void {
        if(!this.processingButtonClick && this.selectedMatching) {
            this.processingButtonClick = true;
            MatchingConnector.cancelMatching(this.selectedMatching)
                .then(() => this.loadMatching(this.$route.params.id))
                .catch(
                    error => {
                        defaultFetchErrorHandler(error);
                        this.error = error;
                        this.processingButtonClick = false;
                    }
                );
        }
    }
}

