






















































































import {Component, Vue} from "vue-property-decorator";
import {ViewLabel} from "@/store/types";
import {
    FLUENT_ICON_PARTNERS, FLUENT_ICON_PROCEED,
    STATE_PARTNER,
    STATE_PARTNER_DETAILS
} from "@/types/StateConst";
import DxButton from "devextreme-vue/button";
import DxDataGrid, {DxEditing, DxForm, DxPopup} from "devextreme-vue/data-grid";
import {Partner} from "@/types/Partner";
import partnerSource, {remoteOperations} from "@/components/partner/PartnerListSource";
import {RemoteOperations} from "@/types/RemoteOperations";
import DxTextBox from "devextreme-vue/text-box";
import {EventBus} from "@/main";
import PartnerConnector from "@/components/partner/PartnerConnector";
import DxCheckBox from "devextreme-vue/check-box";
import StatusIcon from "@/components/transaction/StatusIcon.vue";
import {getInitials} from "@/util/string.module";
import DxTooltip from "devextreme-vue/tooltip";
import {Authentication} from "gipa-universal-frame/src/types/Authentication";
import {State} from "vuex-class";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {PARTNER_LIST_ROUTE_NAME} from "@/router";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import dxDataGridColumn = DevExpress.ui.dxDataGridColumn;
import DataSource = DevExpress.data.DataSource;
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import dxDataGrid from "devextreme/ui/data_grid";
import {DxItem} from "devextreme-vue/form";

@Component({
    components: {
        StatusIcon,
        DxButton,
        DxDataGrid,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxTextBox,
        DxCheckBox,
        DxTooltip,
    },
    methods: {
        getInitials: getInitials,
    },
})

export default class PartnerList extends Vue {
    @State(state => state.auth) auth: Authentication | null | undefined;

    partnerGrid?: dxDataGrid;
    saveGridInstance(e: any): void {
        this.partnerGrid = e.component;
    }

    static readonly STATUS_CAPTION = 'Status';
    static readonly STATUS_FILTER_VALUE = 'ACTIVE'

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        STATE_PARTNER,
        true,
        { name: PARTNER_LIST_ROUTE_NAME }
    );

    preFilter: boolean = true;

    iconProceed: string = FLUENT_ICON_PROCEED;

    error: any = null;

    partners: DataSource = partnerSource;
    notFoundText: string = "Es liegen keine Partner vor."
    remotePartnerOperations: RemoteOperations = remoteOperations;

    public static readonly BASIC_VIEW_LABEL: ViewLabel = {
        primary: "Übersicht Partner",
        secondary: false,
        icon: FLUENT_ICON_PARTNERS,
        cssClass: "Partner",
    };

    private readonly createPartnerButton: UFButtonOptions = new UFButtonOptions(
        this.createNewPartnerDialog,
        "Partner erstellen",
        "ms-icon ic_fluent_person_add_24_regular",
        "Partner erstellen"
    );

    dxColumns: dxDataGridColumn[] = [
        {
            caption: '',
            allowSorting: false,
            cssClass: "align-middle",
            allowFiltering: false,
            dataType: "object",
            cellTemplate: "partnerIconTemplate"
        },
        {
            caption: 'Name',
            dataField: 'name',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
            allowFiltering: true,
            validationRules: [{
                type: "required"
            }]
        },
        {
            caption: PartnerList.STATUS_CAPTION,
            dataField: 'state',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterValue: PartnerList.STATUS_FILTER_VALUE,
            filterOperations: ['='],
            allowFiltering: true,
        },
        {
            caption: 'Version',
            dataField: 'version',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
            allowFiltering: true,
        },
        {
            caption: 'Anleger',
            dataField: 'createdBy',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
            allowFiltering: true,
        },
        {
            caption: 'Authentifizierung',
            dataField: 'authentication',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['='],
            allowFiltering: true,
            calculateCellValue: function (rowData: Partner): string {
                if (rowData.basicAuth) {
                    return "BasicAuthentication";
                } else if (rowData.oAuth) {
                    return "OAuth2Authentication";
                } else {
                    return "";
                }
            }
        },
        {
            caption: 'interner OAuthUser',
            dataField: 'gipaOAuthUser',
            allowSorting: true,
            cssClass: "align-middle multilineCell",
            dataType: "string",
            filterOperations: ['contains'],
            allowFiltering: true,
            validationRules: [{
                type: "required"
            },{
                type: "async",
                message: "Es existiert bereits ein Partner mit diesem OAuthUser",
                validationCallback: this.checkOAuthIsUnique,
            }]
        },
        {
            cellTemplate: "commandTemplate",
            allowSorting: false,
        },
    ];


    mounted(): void {
        this.$store.commit("clearMatching");
        this.$store.commit("clearAgreement");
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
        this.$store.commit("updateViewLabel", PartnerList.BASIC_VIEW_LABEL);

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.createPartnerButton);
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.createPartnerButton);
    }

    handleRefresh(): void {
        this.partners.reload();
    }

    createNewPartnerDialog(): void {
        this.partnerGrid?.addRow();
    }

    forwardToPartnerDetails(e: any): void {
        this.$store.commit("selectPartner", e.data);
        this.$router.push({
            name: 'partnerDetails',
            params: {
                id: e.data.partnerId,
                toolName: STATE_PARTNER_DETAILS,
            },
        });
    }

    checkOAuthIsUnique(e: any): Promise<boolean> {
        return PartnerConnector.isGipaOAuthUserUnique(e.value);;
    }

    proceedToPartner(partner: Partner): void {
        if (partner.partnerId != null) {
            this.$store.commit("selectPartner", partner);
            this.$router.push({
                name: 'partnerDetails',
                params: {id: partner.partnerId, toolName: STATE_PARTNER_DETAILS,},
            });
        }
    }

    filterPartners(): void {
        if (!this.partnerGrid) {
            // initialization of this view is not done yet.
            return;
        }
        if (this.preFilter) {
            this.partnerGrid.columnOption(
                PartnerList.STATUS_CAPTION,
                "filterValue",
                PartnerList.STATUS_FILTER_VALUE
            )
        } else {
            this.partnerGrid.columnOption(
                PartnerList.STATUS_CAPTION,
                "filterValue",
                null
            )
        }
    }

}
