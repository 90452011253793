








import {Component, Vue} from "vue-property-decorator";
import ToolHeaderBar from "@/components/frame/ToolHeaderBar.vue";
import TenantHead from "@/components/frame/TenantHead.vue";
import {State} from "vuex-class";

@Component({
    components: {
        ToolHeaderBar,
        TenantHead
    }
})
export default class ToolView extends Vue {
    @State(state => state.showToolHeaderBar) showToolHeaderBar!: boolean;
}
