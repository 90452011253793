
export default class DocumentType {
    readonly avalId: string;
    readonly description: string;

    constructor(json: DocumentTypeJson) {
        this.avalId = json.id;
        this.description = json.description;
    }

}

export interface DocumentTypeJson {
    id: string;
    description: string;
}
