import {Transaction} from "@/types/Transaction/Transaction";
import {TimePeriod} from "@/types/Transaction/TimePeriod";
import {ServiceResponseDiff} from "@/types/Transaction/ServiceResponse";
import {AdditionalContent} from "@/types/AdditionalContent";

export {HistoricTransactionDiff}

/**
 * HistoricTransactionDiff objects have the same fields as HistoricTransaction objects.
 *
 * All unmodified members (comparing the two given HistoricTransactions) are set to 'null'.
 *
 */
class HistoricTransactionDiff {
    readonly status: number;
    readonly statusString: string;
    readonly id: string | null;
    readonly requested: TimePeriod | null;
    readonly planed: TimePeriod | null;
    readonly fulfillment: Date | null;
    readonly serviceAmount: number | null;
    readonly materialAmount: number | null;
    readonly containerAmount: number | null;
    readonly orderNumberExternal: string | null;
    readonly orderNumberInternal: string | null;
    readonly cancellationReason: string | null;
    readonly serviceResponse: ServiceResponseDiff;

    // lastSync will be overwritten, since syncing generates a new History entry that only differs in this value
    // and for visualization such history changes will be merged into one entry by setting lastSync on the first
    // diff entry.
    lastSync: Date | null;
    synced: boolean;
    syncCanceled: boolean | false;

    // the following members are used as headline for DiffEntry (meaning: their value must not be null)
    readonly lastUpdateAt: Date;
    readonly lastUpdateBy: string;
    readonly createdBy: string;
    readonly extendedInformation: Map<string, AdditionalContent> | null;

    constructor(t1: Transaction, t2: Transaction | null) {
        this.id = HistoricTransactionDiff._valueDiff(t1.id, t2 ? t2.id : null);
        this.requested = HistoricTransactionDiff._valueDiff(t1.requested, t2 ? t2.requested : null);
        this.planed = HistoricTransactionDiff._valueDiff(t1.planed, t2 ? t2.planed : null);
        this.fulfillment = HistoricTransactionDiff._valueDiff(t1.fulfillment, t2 ? t2.fulfillment : null);
        this.serviceAmount = HistoricTransactionDiff._valueDiff(t1.serviceAmount, t2 ? t2.serviceAmount : null);
        this.materialAmount = HistoricTransactionDiff._valueDiff(t1.materialAmount, t2 ? t2.materialAmount : null);
        this.containerAmount = HistoricTransactionDiff._valueDiff(t1.containerAmount, t2 ? t2.containerAmount : null);
        this.orderNumberExternal = HistoricTransactionDiff._valueDiff(t1.orderNumberExternal, t2 ? t2.orderNumberExternal : null);
        this.orderNumberInternal = HistoricTransactionDiff._valueDiff(t1.orderNumberInternal, t2 ? t2.orderNumberInternal : null);
        this.cancellationReason = HistoricTransactionDiff._valueDiff(t1.cancellationReason, t2 ? t2.cancellationReason : null);
        this.serviceResponse = new ServiceResponseDiff(t1.serviceResponse, t2 ? t2.serviceResponse : null);
        this.lastSync = HistoricTransactionDiff._valueDiff(t1.lastSync, t2 ? t2.lastSync : null);
        this.extendedInformation = HistoricTransactionDiff._mapDiff(t1.extendedInformation, t2 ? t2.extendedInformation : null);

        // these values must always be in the historyDiff (even if unchanged, e.g. updates for status 7 - advised)
        this.status = t1.status;
        if (t1.statusString) {
            this.statusString = t1.statusString;
        } else {
            this.statusString = "";
        }
        this.lastSync = t1.lastSync;
        this.synced = t1.lastSync !== null;
        this.syncCanceled = t1.syncCanceled;
        this.lastUpdateAt = t1.lastUpdateAt;
        this.lastUpdateBy = t1.lastUpdateBy;
        this.createdBy = t1.createdBy;
    }

    static _valueDiff(value1: any, value2: any) {
        if (this._areEqualTimePeriods(value1, value2)
            || this._areEqualTimes(value1, value2)
            || (value1 === value2)
        )
            return null;
        else
            return value1;
    }

    static _areEqualTimePeriods(value1: any, value2: any): boolean {
        return (
            value1 instanceof TimePeriod
            && value2 instanceof TimePeriod
            && value1.equals(value2)
        );
    }

    static _areEqualTimes(value1: any, value2: any): boolean {
        return (
            value1 instanceof Date
            && value2 instanceof Date
            && value1.valueOf() === value2.valueOf()
        );
    }

    static _mapDiff(map1: Map<string, AdditionalContent> | null, map2: Map<string, AdditionalContent> | null) {
        const result = new Map<string, AdditionalContent>();
        if (map1 === null || map2 === null) {
            return result;
        }
        for (const [key, value] of map1) {
            const value2 = map2.get(key);
            // for some reason type erasure prevents using equals() of AdditionalContent and fails at runtime!
            // if (!value2 || !value2.equals(value)) {
            if (!value2 || !(value.type === value2.type && value.content === value2.content)) {
                result.set(key, value);
            }
        }
        return result;
    }

}
