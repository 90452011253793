import {Agreement, AgreementJSON} from "@/types/Agreement";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {GenericEntityConnector, LoadOptions, PropertyMapping, ResultPage} from "@gipa/devextreme-to-spring";


export default class AgreementConnector {
    static propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>([
        ["location", "locationString"],
        ["customer", "customer.name"],
    ]));

    static connector: GenericEntityConnector<Agreement> = new GenericEntityConnector<Agreement>(
        AgreementConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "agreements",
        Agreement,
        "customer",
    );

    public static getInstance(): GenericEntityConnector<Agreement> {
        return AgreementConnector.connector;
    }

    public static filterMatchableAgreements(loadOptions: LoadOptions): Promise<ResultPage<Agreement>> {
        const builder = ConnectorFactory.buildBaseClient();
        return new Promise((resolve, reject) => {
            builder
                .follow('agreements', 'search', 'filterMatchableBy')
                .withTemplateParameters(AgreementConnector.connector.extractTemplateValues(loadOptions))
                .getResource(
                    (error: any, data: any) => {
                        if (error) {
                            reject(error);
                            return;
                        }
                        resolve({
                            data: data._embedded.agreements.map(
                                (entityJSON: AgreementJSON) => new Agreement(entityJSON),
                            ),
                            totalCount: data.page.totalElements,
                        });
                    }
                );
        });
    }

}
