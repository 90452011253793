import {OAuth2Authentication} from "@/types/OAuth2Authentication";
import {BasicAuthentication} from "@/types/BasicAuthentication";

export {Partner};

interface CreatedPartner {
    name: string;
    gipaOAuthUser: string;
}

class Partner {
    partnerId: string | null;
    self: string;
    name: string;
    state: string;
    uri: string;
    version: string;
    createdAt: string;
    createdBy: string;
    basicAuth: BasicAuthentication | null;
    oAuth: OAuth2Authentication | null;
    gipaOAuthUser: string;

    constructor();
    constructor(json: PartnerJSON);
    constructor(json?: PartnerJSON) {
        if (json) {
            this.partnerId = json.partnerId;
            this.self = json._links.self.href;
            this.name = json.name;
            this.state = json.state;
            this.uri = json.uri;
            this.version = json.version;
            this.createdAt = json.createdAt;
            this.createdBy = json.createdBy;
            this.basicAuth = json.basicAuth;
            this.oAuth = json.oAuth;
            this.gipaOAuthUser = json.gipaOAuthUser;
        } else {
            this.partnerId = null;
            this.self = "";
            this.name = "";
            this.state = "";
            this.uri = "";
            this.version = "";
            this.createdAt = "";
            this.createdBy = "";
            this.basicAuth = null;
            this.oAuth = null;
            this.gipaOAuthUser = "";
        }
    }

    getBackgroundStatusClass(): string {
        if (this.oAuth != null ) {
            return "backOauth2Status";
        }
        return "backBasicAuthStatus";
    }

    static fromJSON(json: PartnerJSON): Partner {
        return new Partner(json);
    }

}

export interface PartnerJSON {
    _links: {
        self: {
            href: string;
        };
    };
    name: string;
    state: string;
    uri: string;
    version: string;
    createdAt: string;
    createdBy: string;
    basicAuth: BasicAuthentication | null;
    oAuth: OAuth2Authentication | null;
    gipaOAuthUser: string;
    partnerId: string;
}
