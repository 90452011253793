





















import {Component, Vue} from "vue-property-decorator";
import {TimePeriod} from "@/types/Transaction/TimePeriod";
import {getDateString} from "@/util/string.module";
import {formatDate, formatMessage, loadMessages} from "devextreme/localization";

@Component({
    components: {
    },
    props: {
        period: {
            type: TimePeriod,
        },
    },
    methods: {
        getDateString: getDateString,
    },
})
export default class TimePeriodElement extends Vue {
    created() {
        loadMessages({
            'en': {
                'timeString': '{0}',
                'noPeriod': 'no information'
            },
            'de': {
                'timeString': '{0} Uhr',
                'noPeriod': 'keine Angabe'
            }
        });
    }

    noPeriod(): string {
        return formatMessage('noPeriod', "");
    }

    localTimeString(date: Date): string {
        return formatMessage('timeString', formatDate(date, "shortTime"))
    }

    _isDifferentDay(): boolean {
        return getDateString(this.$props.period.start) !== getDateString(this.$props.period.end);
    }

}

