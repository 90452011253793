import TransactionConnector from "@/components/transaction/TransactionConnector";
import {RemoteOperations} from "@/types/RemoteOperations";
import DataSource from "devextreme/data/data_source";

export const sortOptions: any = {
    orderNumberSupplier: "orderNumberSupplier",
    orderNumberClient: "orderNumberClient",
    operationStartTimestamp: "operationStartTimestamp",
    planedFulfillmentStartTimestamp: "planedFulfillmentStartTimestamp",
    lastUpdateAt: "lastUpdateAt",
};

export const remoteOperations: RemoteOperations = {
    paging: true,
    sorting: true,
    filtering: true,
};

const transactionDataSource: DataSource = new DataSource({
    load: function (loadOptions): any {
        return TransactionConnector.getInstance().filter(loadOptions);
    }
});

export default transactionDataSource;
