import {constructDateFromString} from "@/util/string.module";

export default class DocumentTypeMapping {
    readonly id: string;
    readonly avalTypeId: string;
    readonly avalTypeDescription: string;
    readonly dmsTypeId: string;
    readonly createdAt: Date | null;
    readonly createdBy: string;
    readonly updatedAt: Date | null;
    readonly updatedBy: string;
    readonly self: string;

    constructor(json: DocumentTypeMappingJson) {
        this.id = json.id;
        this.avalTypeId = json.avalTypeId;
        this.avalTypeDescription = json.avalTypeDescription;
        this.dmsTypeId = json.dmsTypeId;
        this.createdAt = constructDateFromString(json.createdAt);
        this.createdBy = json.createdBy
        this.updatedAt = constructDateFromString(json.updatedAt);
        this.updatedBy = json.updatedBy;
        this.self = json._links.self;
    }

}

export interface DocumentTypeMappingJson {
    id: string;
    avalTypeId: string;
    avalTypeDescription: string;
    dmsTypeId: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    _links: {
        self: string;
    };
}
