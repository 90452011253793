

















import {Component, Vue} from "vue-property-decorator";
import {retrieveReleasedMatchingsStat} from "@/util/insights.module";
import {defaultFetchErrorHandler} from "@/main";
import {DxButton} from "devextreme-vue";
import {MATCHING_LIST_ROUTE_NAME} from "@/router";
import {PartnerLookupDSO} from "@/components/partner/PartnerLookupDSO";
import DataSource from "devextreme/data/data_source";
import {Partner} from "@/types/Partner";

@Component({
    components: {
        DxButton
    },
})

export default class MatchCustomerWidget extends Vue {
    partnerName: string = "";
    requestName: string = "";
    matchedTotal: string = "";

    partnerLookup: DataSource = new DataSource(PartnerLookupDSO);

    routeToFilteredMatchings(customer: string): void {
        this.$router.push({name: MATCHING_LIST_ROUTE_NAME, params: { customer: customer }});
    }

    mounted() {
        retrieveReleasedMatchingsStat().then(stats => {
            this.requestName = stats.name;
            this.partnerLookup.store().byKey(this.requestName).then((partner: Partner) => {
                this.partnerName = partner.name;
                if (stats.total == 1) {
                    this.matchedTotal = stats.total.toString() + " Matching";
                }
                else {
                    this.matchedTotal = stats.total.toString() + " Matchings";
                }
            });
        }).catch(defaultFetchErrorHandler);
    }

}
