import {constructDateFromString} from "@/util/string.module";

export {DocumentStatus, DocumentStatusJSON};

class DocumentStatus {
    readonly documentId: string;
    readonly dmsTypId: string;
    readonly avalTypeId: string;
    readonly filename: string;
    readonly syncedAt: Date | null;

    constructor(json: DocumentStatusJSON) {
        this.documentId = json.documentId;
        this.dmsTypId = json.dmsTypId;
        this.avalTypeId = json.avalTypeId;
        this.filename = json.filename;
        this.syncedAt = constructDateFromString(json.syncedAt);
    }
}

interface DocumentStatusJSON {
    documentId: string;
    dmsTypId: string;
    avalTypeId: string;
    filename: string;
    syncedAt: string;
}
