

























































import {Component, Vue} from "vue-property-decorator";
import {ViewLabel} from "@/store/types";
import {FLUENT_ICON_ARROW_DOWN, FLUENT_ICON_ARROW_UP, FLUENT_ICON_HOME} from "@/types/StateConst";
import {DxList} from 'devextreme-vue';
import StatusWidget from "@/components/home/StatusWidget.vue";
import MatchPositionWidget from "@/components/home/MatchPositionWidget.vue";
import WeeklyChartWidget from "@/components/home/WeeklyChartWidget.vue";
import MatchCustomerWidget from "@/components/home/MatchCustomerWidget.vue";
import DataSource from 'devextreme/data/data_source';
import DxButton from "devextreme-vue/button";
import {MatchingDataSource, sortOptions} from "@/components/matching/MatchingDataSource";
import {getDateString} from "@/util/string.module";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {CHANGE_BREADCRUMB} from '@/types/MutationConst';
import {HOME_ROUTE_NAME} from '@/router'
import MatchingCard from "@/components/home/MatchingCard.vue";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {EventBus} from "@/main";

@Component({
    components: {
        MatchingCard,
        MatchCustomerWidget,
        WeeklyChartWidget,
        MatchPositionWidget,
        StatusWidget,
        DxList,
        DxButton
    },
    methods: {
        getDateString: getDateString,
    },
})
export default class Home extends Vue {

    public static readonly VIEW_LABEL: Readonly<ViewLabel> = {
        primary: "Dashboard",
        secondary: false,
        icon: FLUENT_ICON_HOME,
        cssClass: "Home",
    };

    private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
        "Dashboard",
        true,
        { name: HOME_ROUTE_NAME }
    );

    error: any = null;
    currentItem: any;

    itemList: DataSource = new MatchingDataSource();
    searchMode: string = "contains";
    searchEx: string[] = ['id', 'customerName', 'requestDate', 'status'];
    sortKey = sortOptions;
    renderToggle: boolean = false;
    sortIcons: any = {
        [sortOptions.customer]: null,
        [sortOptions.date]: FLUENT_ICON_ARROW_UP,
        [sortOptions.material]: null,
        [sortOptions.status]: null,
    };

    mounted() {
        this.$store.commit("clearMatching");
        this.$store.commit("clearAgreement");
        this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
        this.$store.commit("updateViewLabel", Home.VIEW_LABEL);
        this.itemList.pageIndex(0);
        this.itemList.sort({selector: this.sortKey.date, desc: true});

        EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh)
    }

    beforeDestroy(): void {
        EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
    }

    handleRefresh(): void {
        this.itemList.reload();
        this.refreshView();
    }

    data(): any {
        return {Home};
    }

    listSelectionChanged(e: any): void {
        this.currentItem = e.addedItems[0];
    }

    refreshView(): void {
        this.renderToggle = !this.renderToggle;
    }

    updateSortIcons(currentSelector: string, newSelector: string, desc: boolean): void {
        this.sortIcons[currentSelector] = null;
        if (desc) {
            this.sortIcons[newSelector] = FLUENT_ICON_ARROW_UP;
        } else {
            this.sortIcons[newSelector] = FLUENT_ICON_ARROW_DOWN;
        }
    }

    sort(newSelector: string): void {
        let desc: boolean = false;
        const currentSort: any = this.itemList.sort();
        if (currentSort) {
            const currentSelector: string = currentSort.selector;
            if (currentSelector === newSelector) {
                desc = !currentSort.desc;
            }
            this.updateSortIcons(currentSelector, newSelector, desc);
        }
        this.itemList.pageIndex(0);
        this.itemList.sort({selector: newSelector, desc: desc});
        this.itemList.load();
    }

}
