import {BACKEND_BASE_URL, ConnectorFactory} from "@/util/ConnectorFactory";
import {getDateString} from "@/util/string.module";
import {WeeklyStackBarStats} from "@/types/WeeklyStackBarStats";

const MOST_MATCH_URI = BACKEND_BASE_URL + "/transactions/search/getTopCustomer";
const MOST_RELEASED_MATCH_URI = BACKEND_BASE_URL + "/matchings/search/getTopRequester";
const WEEKLY_STATS_URI = BACKEND_BASE_URL + "/transactions/search/getSumOfDailyTransactionsForLastFourWeeks";

interface MatchingStats {
    readonly state: number;
    readonly count: number;
    readonly latest: Date;
    readonly asSupplier: boolean;
}

interface MostTransactionStat {
    readonly name: string;
    readonly total: number;
}

interface MostReleasedMatchStat {
    readonly name: string;
    readonly total: number;
}


export class StatisticTuple {
    count: number;
    classifier: string;

    constructor(count: number = 0, classifier: string | Date = "-") {
        this.count = count;
        if (classifier instanceof Date) {
            this.classifier = getDateString(classifier);
        } else {
            this.classifier = classifier;
        }
    }
}

function parseMatchingStats(response: Array<MatchingStats>): Map<string, StatisticTuple> {
     const result: Map<string, StatisticTuple> = new Map<string, StatisticTuple>();
    response.forEach((element: MatchingStats) => {
        result.set(element.state.toString() + element.asSupplier, new StatisticTuple(element.count, new Date(element.latest)));
    });
    return result;
}

export async function retrieveMatchingStats(): Promise<Map<string, StatisticTuple>> {
    const traverson = ConnectorFactory.buildBaseClient();
    return new Promise((resolve, reject) => {
        traverson
            .follow('matchings', 'search', 'matchingStats')
            .get((error: any, response: any) => {
                if (error) {
                    reject(error);
                } else {
                    const body = JSON.parse(response.body);
                    if (body.error) {
                        reject(body.error);
                    } else {
                        resolve(parseMatchingStats(body._embedded.matchingInsightses));
                    }
                }
            });
    });
}

export async function retrieveMostMatchTransactionStat(): Promise<MostTransactionStat> {
    const builder = ConnectorFactory.buildBaseClient(MOST_MATCH_URI);
    return new Promise((resolve, reject) => {
        builder.get(function (error: any, response: any) {
            if (error) {
                reject(error);
            } else {
                const body = JSON.parse(response.body);
                if (body.error) {
                    reject(body.error);
                } else {
                    resolve(body);
                }
            }
        });
    });
}

export async function retrieveReleasedMatchingsStat(): Promise<MostReleasedMatchStat> {
    const builder = ConnectorFactory.buildBaseClient(MOST_RELEASED_MATCH_URI);
    return new Promise((resolve, reject) => {
        builder.get(function (error: any, response: any) {
            if (error) {
                reject(error);
            } else {
                const body = JSON.parse(response.body);
                if (body.error) {
                    reject(body.error);
                } else {
                    resolve(body);
                }
            }
        });
    });
}

export async function retrieveWeeklyStats(): Promise<Array<WeeklyStackBarStats>> {
    const builder = ConnectorFactory.buildBaseClient(WEEKLY_STATS_URI);
    return new Promise((resolve, reject) => {
        builder.get(function (error: any, response: any) {
            if (error) {
                reject(error);
            } else {
                const body = JSON.parse(response.body);
                if (body.error) {
                    reject(body.error);
                } else {
                    resolve(body._embedded.dailyAggregations.map(WeeklyStackBarStats.fromJSON));
                }
            }
        });
    });
}
