import {DocumentStatus, DocumentStatusJSON} from "@/types/Transaction/Document/DocumentStatus";

export {SyncDetails, SyncDetailsJSON};

class SyncDetails {
    readonly transactionId: string;
    readonly matchingId: string;
    readonly oAuthUserForSync: string;
    readonly syncingNeeded: boolean;
    readonly documentStatusList: DocumentStatus[];

    constructor(json: SyncDetailsJSON) {
        this.transactionId = json.transactionId;
        this.matchingId = json.matchingId;
        this.oAuthUserForSync = json.oAuthUserForSync;
        this.syncingNeeded = json.syncingNeeded;
        this.documentStatusList = json.documentStatusList.map(elem => new DocumentStatus(elem));
    }
}

interface SyncDetailsJSON {
    transactionId: string;
    matchingId: string;
    oAuthUserForSync: string;
    syncingNeeded: boolean;
    documentStatusList: DocumentStatusJSON[];

}
