import {store} from "@/main";
import router from "@/router";
import * as Traverson from 'traverson';
import {AUTHENTICATED} from "@/types/MutationConst";

const TOKEN_URI: string = "/token";
const BASIC_AUTHENTICATION: string = "Basic c2FtcGxlLWNsaWVudDpzYW1wbGUtc2VjcmV0";
const AUTH_BASE_URL: string =  location.hostname === "localhost" ?
    "http://localhost:7777/oauth" : "https://" + location.hostname + ":7777/oauth";

export function authenticate(username: string, password: string, redirectPath: string): void {

    Traverson.from(AUTH_BASE_URL + TOKEN_URI)
        .newRequest()
        .withRequestOptions({
            headers: {
                "Authorization": BASIC_AUTHENTICATION
            },
            form: {
                "grant_type": "password",
                "username": username,
                "password": password
            }
        })
        .post(null, function (error: any, response: any) {
            if (error) {
                console.error("Authentication failed with error '" + error + "'");
                store.commit("updateAuthenticationDisplayMessage", "Es ist ein Fehler aufgetreten.\n" + error);
            } else {
                const body = JSON.parse(response.body);
                if (body.error) {
                    console.error("Authentication failed with message '" + JSON.stringify(body) + "'");
                    store.commit("updateAuthenticationDisplayMessage", "Ihr Benutzername oder Kennwort ist nicht korrekt.");
                } else {
                    if (response.statusCode === 200) {
                        handleAuthenticationResult(body.access_token, redirectPath);
                    } else {
                        store.commit("updateAuthenticationDisplayMessage", "Fehler bei der Anmeldung! Status" + response.statusCode);
                        console.error("Authentication failed with status code '" + response.statusCode + "'");
                    }
                }
            }
        });
}

function handleAuthenticationResult(token: string, redirectPath?: string): void {
    store.commit(AUTHENTICATED, token);
    if (redirectPath) {
        router.push(redirectPath);
    } else {
        router.push('/');
    }
}
