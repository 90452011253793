import {ConnectorFactory} from '@/util/ConnectorFactory';
import {Transaction, TransactionJSON} from "@/types/Transaction/Transaction";

export {fetchHistory};

function fetchHistory(transactionId: string): Promise<Array<Transaction>> {
    const traverson = ConnectorFactory.buildBaseClient();
    return new Promise((resolve, reject) => {
        traverson
            .follow('transactionHistories', 'search', 'findByTransactionId')
            .withTemplateParameters({transactionId: transactionId})
            .getResource((error: any, data: any) => {
                if (error) {
                    reject(error);
                    return;
                }
                resolve(
                    (data.transactions).map((json: TransactionJSON) => new Transaction(json))
                );
            });
    });

}
