







import {Component, Vue} from 'vue-property-decorator';
import UniFrame from "gipa-universal-frame";
import {State} from "vuex-class";
import {AvalState} from "@/store/types"
import {Authentication} from "gipa-universal-frame/src/types/Authentication";
import Login from "./components/login/Login.vue"
import ToolView from "@/components/frame/ToolView.vue";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {ADD_COMMAND_BAR_ELEMENT, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {EventBus} from "@/main";
import {iniDefaultOptions} from "@/components/configuration/dxDefaultOptions";
import {FLUENT_ICON_RELOAD} from "@/types/StateConst";

@Component({
    "components": {
        UniFrame,
        Login,
        ToolView
    },
})
export default class App extends Vue {
    @State((state) => state) protected stateGlobal!: AvalState;
    @State((state) => state.auth) auth!: Authentication;

    private readonly refreshCommandButton: UFButtonOptions = new UFButtonOptions(
        () => {
            EventBus.$emit(COMMAND_BAR_REFRESH_EVENT)
        },
        "Aktualisieren",
        FLUENT_ICON_RELOAD,
        "Inhalte Aktualisieren"
    );

    created(): void {
        iniDefaultOptions();
        this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.refreshCommandButton);
    }

    beforeDestroy(): void {
        this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.refreshCommandButton);
    }
}

