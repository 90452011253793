




















import {Component, Vue} from "vue-property-decorator";
import {Agreement} from "@/types/Agreement";
import {State} from "vuex-class";

@Component({
    components: {},
})

export default class AgreementContext extends Vue {
    @State(state => state.agreement) agreement: Agreement | null | undefined;
}
