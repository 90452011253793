import {GenericEntityConnector, PropertyMapping} from "@gipa/devextreme-to-spring";
import DocumentTypeMapping, {DocumentTypeMappingJson} from "@/types/DocumentTypeMapping";
import {ConnectorFactory} from "@/util/ConnectorFactory";
import {defaultFetchErrorHandler} from "@/main";

export default class DocumentTypeMappingConnector {
    private static readonly propertyMapping: PropertyMapping = new PropertyMapping(new Map<string, string>());

    static connector: GenericEntityConnector<DocumentTypeMapping> = new GenericEntityConnector<DocumentTypeMapping>(
        DocumentTypeMappingConnector.propertyMapping,
        ConnectorFactory.buildBaseClient,
        "documentTypeMappings",
        DocumentTypeMapping,
        "avalTypeId"
    );

    public static getInstance(): GenericEntityConnector<DocumentTypeMapping> {
        return DocumentTypeMappingConnector.connector;
    }

    static fetchDocumentTypeMappingById(key: string) {
        const traverson = ConnectorFactory.buildBaseClient();
        return new Promise<DocumentTypeMapping>((resolve, reject) => {
            traverson
                .follow('documentTypeMappings', 'search', 'getById')
                .withTemplateParameters({id: key})
                .getResource((error: any, response: DocumentTypeMappingJson) => {
                    if (error) {
                        defaultFetchErrorHandler(error);
                        reject();
                    }
                    resolve(new DocumentTypeMapping(response));
                })
        })
    }
}
