
export {TimePeriod}

class TimePeriod {
    readonly start: Date;
    readonly end: Date;

    constructor(start: string, end: string) {
        this.start = new Date(start);
        this.end = new Date(end);
    }

    equals(other: TimePeriod) {
        return (this.start.getTime() === other.start.getTime() && this.end.getTime() === other.end.getTime());
    }
}
