
export {TenantContext, Tenant, TenantRole}

const DEFAULT_NAME: string = "HIVE";
const DEFAULT_ROLE: TenantRole = "TENANT_DEFAULT"

type TenantRole = string;

class Tenant {
    role: TenantRole = DEFAULT_ROLE;
    name: string = DEFAULT_NAME;

    constructor()
    constructor(role: TenantRole, name: string)
    constructor(role?: TenantRole, name?: string) {
        this.role = role ?? DEFAULT_ROLE;
        this.name = name ?? DEFAULT_NAME;
    }

}

class TenantContext {
    private _activeTenantRole: TenantRole = DEFAULT_ROLE;
    private _activeTenantName: string = DEFAULT_NAME;
    // _allowedRoles - contains all tenantRoles the logged in user has permission to request
    private _allowedRoles: TenantRole[] = []
    // _existingTenants - contains all tenants (role & display name) known by the backend
    private _existingTenants: Tenant[] = [];
    private _desiredTenantRole: TenantRole = "";

    get activeTenantRole(): TenantRole {
        return this._activeTenantRole;
    }

    get activeTenantName(): string {
        return this._activeTenantName;
    }

    set desiredTenant(role: TenantRole) {
        if (!this._allowedRoles.includes(role)) {
            console.warn("[WARN]: refuse to set role " + role + "active, it is not in list of allowed roles " + this._allowedRoles);
            return;
        }
        this._desiredTenantRole = role;
        this._switchToDesiredTenant();
    }

    set allowedTenants(roles: string[]) {
        this._allowedRoles = roles.map(r => r.replace("ROLE_",""));
        this._switchToDesiredTenant();
    }

    set existingTenants(existing: Tenant[]) {
        this._existingTenants = existing;
        // update _activeTenantName, because to get existing tenants a role has already been activated (with the default name)
        this._updateName();
    }

    get accessibleTenants(): Tenant[] {
        return this._existingTenants
            .filter((tenant: Tenant) => {return this._allowedRoles.includes(tenant.role)})
            .sort((a: Tenant, b: Tenant) => a.name.localeCompare(b.name));
    }

    private _updateName(): void {
        this._activeTenantName = this._existingTenants.filter((tenant: Tenant) => {return tenant.role === this._activeTenantRole}).pop()?.name ?? DEFAULT_NAME;
    }

    private _switchToDesiredTenant() {
        if (this._allowedRoles.includes(this._desiredTenantRole)) {
            this._activeTenantRole = this._desiredTenantRole;
        } else {
            this._activeTenantRole = this._allowedRoles[0];
        }
        this._updateName();
    }
}
