































import {Component, Vue} from "vue-property-decorator";
import {Matching, StatusEnum} from "@/types/Matching";
import {State} from "vuex-class";

@Component({
    components: {
    },
})
export default class MatchingStatusBar extends Vue {
    @State(state => state.matching) matching?: Matching;

    getFirstLineCss(matching: Matching): string {
        if (this.isMatchingCreatedAndClient(matching) || matching.status === StatusEnum.matched) {
            return "lineActive";
        }
        return "lineInactive";
    }

    getWaitForMatchingStepCircle(matching: Matching): string {
        if (this.isMatchingCreatedAndClient(matching) || matching.status === StatusEnum.matched) {
            return "circleActive";
        }
        return "circleInactive";
    }

    getSecondLineCss(matching: Matching): string {
        if (matching.status === StatusEnum.matched) {
            return "lineActive";
        }
        return "lineInactive";
    }

    getNewStepCircle(matching: Matching): string {
        if (matching.status !== StatusEnum.cancelled) {
            return "circleActive";
        }
        return "circleInactive";
    }

    getMatchedStepCircle(matching: Matching): string {
        if (matching.status === StatusEnum.matched) {
            return "circleActive";
        }
        return "circleInactive";
    }

    isMatchingCreatedAndClient(matching: Matching): boolean {
        if (matching.status == StatusEnum.new && !matching.isSupplier) {
            return true;
        }
        return false;
    }
}
