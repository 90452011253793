import {TimePeriod} from "@/types/Transaction/TimePeriod";
import {ServiceResponse} from "@/types/Transaction/ServiceResponse";
import {AdditionalContent} from "@/types/AdditionalContent";
import {constructDateFromString} from "@/util/string.module";

export {TransactionJSON, Transaction}

type StatusMapping = {
    status: number;
    name: string;
}

class Transaction {
    static statusMappingArray: Array<StatusMapping> = [
        {status: -1, name: 'Synchronisation abgebrochen'},
        {status: 1, name: 'Beauftragung aufgeben'},
        {status: 2, name: 'Technisch akzeptiert'},
        {status: 3, name: 'Technisch abgelehnt'},
        {status: 4, name: 'Fachlich akzeptiert'},
        {status: 5, name: 'Fachlich abgelehnt'},
        {status: 6, name: 'Stornierung'},
        {status: 7, name: 'Avisierung'},
        {status: 8, name: 'Leistung erbracht'},
        {status: 9, name: 'Rückmeldung'},
        {status: 10, name: 'Rückmeldung mit Abweichungen'},
    ];
    static syncStateMappingArray: Array<StatusMapping> = [
        {status: 0, name: 'offen'},
        {status: 1, name: 'erfolgt'},
        {status: 2, name: 'abgebrochen'},
    ];

    readonly status: number;
    readonly statusString: string | undefined;
    readonly id: string;
    readonly avalId: string;
    readonly requested: TimePeriod | null;
    readonly planed: TimePeriod | null;
    readonly fulfillment: Date | null;
    readonly serviceAmount: number;
    readonly materialAmount: number;
    readonly containerAmount: number;
    readonly orderNumberExternal: string;
    readonly orderNumberInternal: string;
    readonly cancellationReason: string;
    readonly serviceResponse: ServiceResponse;
    readonly lastSync: Date | null;
    readonly lastUpdateAt: Date;
    readonly lastUpdateBy: string;
    readonly createdBy: string;
    readonly synced: boolean;
    readonly syncStateSince: Date;
    readonly asSupplier: boolean;
    readonly extendedInformation: Map<string, AdditionalContent>;
    readonly oAuthUserForSync: string;
    readonly syncCanceled: boolean = false;
    readonly syncState: number;

    constructor(json: TransactionJSON) {
        this.status = json.state;
        this.statusString = Transaction._constructStatusString(json.state);
        this.id = json.transactionId;
        this.avalId = json.avalId;
        this.requested = Transaction._constructTimePeriod(json.operationStartTimestamp, json.operationEndTimestamp);
        this.planed = Transaction._constructTimePeriod(json.planedFulfillmentStartTimestamp, json.planedFulfillmentEndTimestamp);
        this.fulfillment = constructDateFromString(json.fulfillmentTimestamp);
        this.serviceAmount = json.serviceAmount;
        this.materialAmount = json.materialAmount;
        this.containerAmount = json.containerAmount;
        this.asSupplier = json.asSupplier;
        if (this.asSupplier) {
            this.orderNumberExternal = json.orderNumberClient;
            this.orderNumberInternal = json.orderNumberSupplier;
        } else {
            this.orderNumberExternal = json.orderNumberSupplier;
            this.orderNumberInternal = json.orderNumberClient;
        }
        this.cancellationReason = json.cancellationReason;
        this.serviceResponse = new ServiceResponse(json);
        this.lastSync = constructDateFromString(json.lastSync);

        this.lastUpdateAt = new Date(json.lastUpdateAt);
        this.lastUpdateBy = json.lastUpdateBy;
        this.createdBy = json.createdBy;

        this.synced = this.lastSync !== null && this.lastSync.valueOf() >= this.lastUpdateAt.valueOf();
        this.syncStateSince = Transaction._getSyncStateSince(this.lastUpdateAt, this.lastSync, this.synced);

        this.extendedInformation = new Map<string, AdditionalContent>();

        for (const key of Object.keys(json.extendedInformation)) {
            const value: AdditionalContent = json.extendedInformation[key];
            this.extendedInformation.set(key, value);
        }

        this.oAuthUserForSync = json.oAuthUserForSync;

        this.syncCanceled = json.syncState == 2;

        this.syncState = json.syncState;
    }

    static _constructStatusString(state: number) {
        return Transaction.statusMappingArray.find( e => e.status === state)?.name;
    }

    static _constructTimePeriod(start: string | null | undefined, end: string | null | undefined): TimePeriod | null {
        if (start && end) {
            return new TimePeriod(start, end);
        }
        return null;
    }

    static _getSyncStateSince(lastUpdate: Date, lastSync: Date | null, synced: boolean): Date {
        if (synced) {
            if (lastSync) {
                return lastSync;
            } else {
                console.error("It is not expected that lastSync is >null< or >undefined< whereas synced is >true<");
                // to be type save, return unrealistic value for synced since date
                return new Date(0);
            }
        }
        return lastUpdate;
    }

}

interface TransactionJSON {
    transactionId: string;
    avalId: string;
    state: number;
    operationStartTimestamp: string;
    operationEndTimestamp: string;
    planedFulfillmentStartTimestamp: string;
    planedFulfillmentEndTimestamp: string;
    fulfillmentTimestamp: string;
    serviceAmount: number;
    materialAmount: number;
    containerAmount: number;
    agreementTitle: string;
    orderNumberSupplier: string;
    orderNumberClient: string;
    cancellationReason: string;
    lastSync: string;
    lastUpdateAt: string;
    lastUpdateBy: string;
    createdBy: string;
    asSupplier: boolean;
    extendedInformation: { [name: string]: AdditionalContent };

    // fields for ServiceResponse
    isUnderMeasureThreshold: boolean;
    serviceNoteNumber: string;
    governmentalAssetNumber: string;
    governmentalCarrierNumber: string;
    complaintReason: number;
    oAuthUserForSync: string;
    irrecoverableSyncingFailure: {statusCode: string; reason: string; timestamp: string};
    syncState: number;
}
