



















import {Component, Vue} from "vue-property-decorator";
import {Agreement} from "@/types/Agreement";
import {State} from "vuex-class";
import {Partner} from "@/types/Partner";

@Component({
    components: {},
})
export default class MatchingCreationStatusBar extends Vue {
    @State(state => state.agreement) agreement?: Agreement;
    @State(state => state.partner) partner?: Partner;

    getLineCss(object: any): string {
        if (object) {
            return "lineActive";
        }
        return "lineInactive";
    }

    getCircleCss(object: any): string {
        if (object) {
            return "circleActive";
        }
        return "circleInactive";
    }
}

