import {Transaction, TransactionJSON} from "@/types/Transaction/Transaction";

export {HalTransaction};

class HalTransaction extends Transaction {
    readonly self: string;

    constructor(json: HalTransactionJSON) {
        super(json);
        this.self = json._links.self.href;
    }

    static fromJSON(object: any): HalTransaction {
        return new HalTransaction(object);
    }

}

interface HalTransactionJSON extends TransactionJSON {
    _links: {
        self: {
            href: string;
        };
    };
}
