































import {Component, Vue} from "vue-property-decorator";
import {DxLoadIndicator} from "devextreme-vue/load-indicator";
import * as TransactionDocumentSyncDetailsConnector from "@/components/transaction/DocumentSyncDetailsConnector";
import {SyncDetails} from "@/types/Transaction/Document/SyncDetails";
import {LOGIN_ROUTE_NAME} from "@/router";
import SyncStatusBadge from "@/components/transaction/SyncStatusBadge.vue";
import {getDateTimeString} from "@/util/string.module";

@Component({
    components: {
        SyncStatusBadge,
        DxLoadIndicator,
    },
    props: {
        transactionId: {
            type: String,
            required: true,
        },
    },
    methods: {
        getDateTimeString: getDateTimeString,
    }
})
export default class TransactionDocumentSyncDetails extends Vue {

    private syncDetails: SyncDetails | null = null;
    private error: any;
    private loading: boolean = true;

    mounted(): void {
        TransactionDocumentSyncDetailsConnector.fetchSyncDetails(this.$props.transactionId)
            .then(this._handleFetchSuccess)
            .catch(this._handleFetchError)
            .finally(
                () => {this.loading = false;}
            );
    }

    _handleFetchSuccess(response: SyncDetails) {
        this.error = null;
        this.syncDetails = response;
    }

    _handleFetchError(error: any): void {
        if (error.httpStatus === 401) {
            this.$store.commit("loggedOut");
            this.$router.push({name: LOGIN_ROUTE_NAME, params: {originPath: this.$router.currentRoute.path}});
        } else if (error.httpStatus === 404) {
            this.syncDetails = null;
            this.error = null;
        } else {
            this.syncDetails = null;
            this.error = error;
        }
    }

}
