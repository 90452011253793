
export const APP_NAME: string = "HIVE";
export const STATE_TOOLNAME ="AvaL System";

export const STATE_HOME: string = "Dashboard";
export const STATE_MATCHING: string = "AvaL Matchings";
export const STATE_TRANSACTION: string = "AvaL Transactions";
export const STATE_AGREEMENT_SELECTION: string = "Vereinbarungen Suche";
export const STATE_AGREEMENT_DETAILS: string ="Vereinbarungen Details";
export const STATE_PARTNER: string = "Partner/Drittsysteme";
export const STATE_PARTNER_DETAILS: string = "Partner Details";
export const STATE_MATCHING_CREATION: string = "Machtinganfrage Erstellung";
export const STATE_DOCUMENT_TYPE_MAPPINGS: string = "Dokument Typ Mapping Konfiguration"

export const STATE_BASIC_AUTHENTICATION = "BasicAuthentication";
export const STATE_OAUTH2_AUTHENTICATION = "OAuth2Authentication";

export const GRANT_TYPE_PASSWORD = "password";
export const GRANT_TYPE_CLIENT_CREDENTIALS = "client_credentials";

// These are the new Icons
export const FLUENT_ICON_HOME = "ms-icon ic_fluent_home_24_regular";
export const FLUENT_ICON_MATCHINGS = "ms-icon ic_fluent_apps_list_24_regular";
export const FLUENT_ICON_TRANSACTIONS = "ms-icon ic_fluent_calendar_clock_24_regular";
export const FLUENT_ICON_PARTNERS = "ms-icon ic_fluent_people_team_24_regular";
export const FLUENT_ICON_AGREEMENT_DETAILS = "ms-icon ic_fluent_edit_24_regular";
export const FLUENT_ICON_AGREEMENT_SELECTION = "ms-icon ic_fluent_slide_multiple_search_24_regular"

export const FLUENT_ICON_MATCHING_CREATION = "ms-icon ic_fluent_add_circle_24_regular";
export const FLUENT_ICON_PROCEED = "ms-icon ic_fluent_arrow_right_24_regular";
export const FLUENT_ICON_REJECT = "ms-icon ic_fluent_dismiss_circle_24_regular";
export const FLUENT_ICON_LINK = "ms-icon ic_fluent_link_square_24_regular"
export const FLUENT_ICON_RELOAD = "ms-icon ic_fluent_arrow_sync_24_regular"
export const FLUENT_ICON_DETAIL = "ms-icon ic_fluent_text_bullet_list_tree_24_regular"
export const FLUENT_ICON_SAVE = "ms-icon ic_fluent_save_24_regular"
export const FLUENT_ICON_DELETE = "ms-icon ic_fluent_delete_24_regular"
export const FLUENT_ICON_ARROW_UP = "ms-icon ic_fluent_arrow_up_24_regular"
export const FLUENT_ICON_ARROW_DOWN = "ms-icon ic_fluent_arrow_down_24_regular"
export const FLUENT_ICON_ZOOM = "ms-icon ic_fluent_zoom_in_24_regular"
export const FLUENT_ICON_QUESTIONMARK = "ms-icon ic_fluent_question_circle_24_regular";
export const FLUENT_ICON_DOCUMENT_TYPE_MAPPINGS = "ms-icon ic_fluent_document_edit_24_regular"
