import * as Traverson from 'traverson';
import * as TraversonHal from 'traverson-hal';
import {store} from "@/main";

export const BACKEND_BASE_URL: string = location.hostname === "localhost" ?
    "http://localhost:7778" : "https://" + location.hostname + ":7778";

export class ConnectorFactory {

    static buildBaseClient(uri? : string) {
        if (!uri) {
            uri = BACKEND_BASE_URL;
        }
        const traverson = Traverson;
        traverson.registerMediaType(TraversonHal.mediaType, TraversonHal);
        return traverson.from(uri)
            .jsonHal()
            .withRequestOptions({
                    headers: { 'Authorization': "bearer " + store.state.auth.jwt,
                            'X-Tenant': store.state.tenantContext.activeTenantRole}
            });
    }

}
