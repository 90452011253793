






























































































































import {Component, Vue} from "vue-property-decorator";
import {Agreement} from "@/types/Agreement";
import {Partner} from "@/types/Partner";
import {State} from "vuex-class";
import {ViewLabel} from "@/store/types";
import {
    FLUENT_ICON_MATCHING_CREATION,
    FLUENT_ICON_REJECT,
    STATE_AGREEMENT_DETAILS,
    STATE_MATCHING_CREATION
} from "@/types/StateConst";
import DxButton from "devextreme-vue/button";
import AgreementConnector from "@/components/matching/AgreementConnector";
import PartnerConnector from "@/components/partner/PartnerConnector";
import MatchingConnector from "@/components/matching/MatchingConnector";
import MatchingCreationStatusBar from "@/components/matchingCreation/MatchingCreationStatusBar.vue";
import {defaultFetchErrorHandler, EventBus} from "@/main";
import notify from "devextreme/ui/notify";
import DxDataGrid, {
    DxColumn,
    DxCustomRule,
    DxEditing,
    DxPager,
    DxPaging,
    DxRequiredRule
} from "devextreme-vue/data-grid";
import DataSource from 'devextreme/data/data_source';
import {AdditionalContent} from "@/types/AdditionalContent";
import {BreadcrumbItem} from "gipa-universal-frame/src/types/BreadcrumbItem";
import {MATCHING_LIST_ROUTE_NAME, MATCHING_REQUEST_ROUTE_NAME} from "@/router";
import {ADD_COMMAND_BAR_ELEMENT, CHANGE_BREADCRUMB, REMOVE_COMMAND_BAR_ELEMENT} from "@/types/MutationConst";
import {UFButtonOptions} from "gipa-universal-frame/src/types/UFButtonOptions";
import {COMMAND_BAR_REFRESH_EVENT} from "@/types/EventButtonConsts";
import {DxForm, DxLoadPanel} from "devextreme-vue";
import {DxButtonItem, DxEmptyItem, DxGroupItem, DxSimpleItem} from "devextreme-vue/form";
import DevExpress from "devextreme";
import dxButtonOptions = DevExpress.ui.dxButtonOptions;
import {ExtendedInformation} from "@/types/ExtendedInformation";

@Component({
        components: {
            MatchingCreationStatusBar,
            DxButton,
            DxDataGrid,
            DxEditing,
            DxColumn,
            DxPaging,
            DxPager,
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            DxButtonItem,
            DxEmptyItem,
            DxLoadPanel,
            DxRequiredRule,
            DxCustomRule
        },
    })

    export default class MatchingRequest extends Vue {
        @State(state => state.agreement) agreement?: Agreement | null;
        @State(state => state.partner) partner?: Partner | null;

        loading: boolean = false;

        error: any = null;
        datasource: DataSource = new DataSource({
            store: {
                type: 'array',
            }
        });
        noText: string = "Kein Inhalt vorhanden - Hier können weitere Informationen hinterlegt werden"

        readonly createIcon: string = FLUENT_ICON_MATCHING_CREATION;

        public static readonly BASIC_VIEW_LABEL: ViewLabel = {
            primary: "Anfrage erstellen",
            secondary: false,
            icon: FLUENT_ICON_MATCHING_CREATION,
            cssClass: "matchingCreation",
        };

        private readonly breadcrumb: Readonly<BreadcrumbItem> = new BreadcrumbItem(
            STATE_MATCHING_CREATION,
            false,
            { name: MATCHING_REQUEST_ROUTE_NAME }
        );

        private readonly cancelCommandButton: UFButtonOptions = new UFButtonOptions(
            () => {
                this.$store.commit("clearAgreement");
                this.$store.commit("clearPartner");
                this.$router.push({name: MATCHING_LIST_ROUTE_NAME});
            },
            "Erstellung abbrechen",
            FLUENT_ICON_REJECT,
            "Den Prozess zum Erstellen eines Matchings abbrechen"
        )

        submitButtonOptions: dxButtonOptions = {
            text: "Erstellen & Senden",
            icon: this.createIcon,
            type: "normal",
            stylingMode: "contained",
            elementAttr: {class: "big-command-button"},
            useSubmitBehavior:true,
            onClick: this.handleSubmitForm
        }

        handleSubmitForm(): void {
            if (this.agreement && this.partner) {
                this.createAndPostMatching(this.agreement.self, this.agreement.summary, this.partner.gipaOAuthUser);
            }
        }

        mounted(): void {
            this.$store.commit("setAgreementContextVisibility", false);
            this.$store.commit(CHANGE_BREADCRUMB, [this.breadcrumb]);
            this.$store.commit("updateViewLabel", MatchingRequest.BASIC_VIEW_LABEL);
            this.$store.commit(ADD_COMMAND_BAR_ELEMENT, this.cancelCommandButton);

            EventBus.$on(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);

            if (this.$store.state.partner === null || this.$store.state.agreement === null) {
                this.loadAgreement(this.$route.params.href);
                this.loadPartner(this.$route.params.partnerId);
            }
        }

        beforeDestroy(): void {
            EventBus.$off(COMMAND_BAR_REFRESH_EVENT, this.handleRefresh);
            this.$store.commit(REMOVE_COMMAND_BAR_ELEMENT, this.cancelCommandButton);
        }


        handleRefresh(): void {
            this.loading = true;
            this.loadAgreement(this.$route.params.href);
            this.loadPartner(this.$route.params.partnerId);
        }

        createAndPostMatching(agreementLink: string, agreementSummary: Role, oAuthUser: string) {
            const extendedInformation: any = {};
            this.datasource.store()._array.forEach((info: { key: string; type: string; content: string }) => {
                extendedInformation[info.key] = new AdditionalContent(info.type, info.content);
            });
            const body = {
                client: agreementSummary,
                oauthUserForSync: oAuthUser,
                matchedAgreement: agreementLink,
                extendedInformation: extendedInformation
            };
            MatchingConnector.getInstance().create(body).then(
                response => {
                    notify("Die Erstellung des Matchings war erfolgreich.")
                    this.$router.push({
                        name: "agreementDetails",
                        params: {
                            id: response.id,
                            toolName: STATE_AGREEMENT_DETAILS,
                        },
                    });
                }, error => {
                    defaultFetchErrorHandler(error);
                    this.error = error;
                }
            )
        }

        loadPartner(partnerId: string): void {
            PartnerConnector.getInstance().loadById(partnerId).then(
                partner => {
                    this.$store.commit("selectPartner", partner);
                }, error => {
                    defaultFetchErrorHandler(error);
                    this.error = error;
                }
            )
        }

        loadAgreement(selfLink: string): void {
            AgreementConnector.getInstance().loadFromHref(selfLink).then(
                agreement => {
                    this.$store.commit("selectAgreement", agreement);
                    this.loading = false;
                },
                error => {
                    defaultFetchErrorHandler(error);
                    this.error = error;
                }
            );
        }

        hasUniqueKey(validationRequest: { data: ExtendedInformation; value: string }): boolean {
            return !this.datasource.items().some((extendedInformation: ExtendedInformation) => {
                return extendedInformation.key === validationRequest.value;
            })
        }

        get filterRowVisible(): boolean {
            return this.datasource.totalCount() > 0;
        }

    }
